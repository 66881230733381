import React from 'react';
import { ProductCategory } from '../../types/yellowHiveTypes';

interface CategoryTabsProps {
  activeCategory: ProductCategory;
  onCategoryChange: (category: ProductCategory) => void;
}

export const CategoryTabs: React.FC<CategoryTabsProps> = ({
  activeCategory,
  onCategoryChange,
}) => {
  return (
    <div className="tabs">
      <button
        onClick={() => onCategoryChange('Particulier')}
        className="tab-button"
        aria-pressed={activeCategory === 'Particulier'}
      >
        Particulier
      </button>
      <button
        onClick={() => onCategoryChange('Bedrijfsmatig')}
        className="tab-button"
        aria-pressed={activeCategory === 'Bedrijfsmatig'}
      >
        Bedrijfsmatig
      </button>
    </div>
  );
};
