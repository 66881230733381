import React from 'react';
import CitySearch, { CitySearchProps } from './CitySearch';
import ManualCity from './ManualCity';

type Props = CitySearchProps & {
  isManualInput: boolean;
  name?: string;
  onBlur?: () => void;
  onFocus?: () => void;
};

const CityPicker: React.FC<Props> = ({ isManualInput, ...props }) => {
  if (isManualInput) return <ManualCity {...props} />;
  return <CitySearch {...props} />;
};

export default CityPicker;
