import useMoveElement from './useMoveElement';

const useMoveHubspotBubble = (paddingBottom = 0): void => {
  const element = getHubspotChatElement();
  useMoveElement({ element, paddingBottom });
};

export const getHubspotChatElement = (): HTMLElement | null =>
  document.getElementById('hubspot-messages-iframe-container');

export default useMoveHubspotBubble;
