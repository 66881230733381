import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AppShell } from '@wegroup/design-system';
import { TopNavigationItemsProvider } from '../../../../common/src/legacy/services/context/TopNavigationBarContext';
import NavigationLoading from '../skeleton-pages/NavigationLoading';
import ProflowLoading from '../skeleton-pages/ProflowLoading';
import ClaimsLoading from '../skeleton-pages/ClaimsLoading';
import ToolkitLoading from '../skeleton-pages/ToolkitLoading';
import NotificationCenterLoading from '../skeleton-pages/NotificationCenterLoading';
import Can from '../permissions/Can';
import LoadingSpinner from '../../../../common/src/components/LoadingSpinner';
import lazyWithRetry from '../../../../common/src/lib/lazyWithRetry';
import LoadingPage from '../../pages/LoadingPage';
import BrokerRoute from './components/BrokerRoute';
import Authentication from '../../pages/Authentication';
import {
  isCampaignsUrl,
  isConversationUrl,
  isFinalizeUrl,
  isLouiseQAUrl,
  isSocialLoginUrl,
} from '../authentication/constants/SocialAuth';
import ConditionalProviders from '../../providers/global-providers/ConditionalProviders';
import useAuth from '../authentication/hooks/useAuth';
import CampaignRedirector from './components/CampaignRedirector';
import AppLoadingDone from '../../components/AppLoadingDone';
import SocialRoute from './components/SocialRoute';
import CampaignsFinalizationView from '../../../../risk-analysis/src/pages/campaigns-finalization';
import { useTranslation } from 'react-i18next';
import RouteNotFoundPage from '../../../../common/src/components/RouteNotFoundPage';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import InsuranceTermsBot from '../../../../toolkit/src/features/louiseqa/pages';
import AskaFinalize from '../../../../sales-conversation/src/pages/aska/finalize';
import YellowHiveRouter from '../../../../yellow-hive/src/router';

const Flows = lazyWithRetry(() => import('../../../../flows/src/Router'));

const Navigation = lazyWithRetry(
  () => import('../navigation/components/Navigation'),
);
const LogoutScreen = lazyWithRetry(() => import('../../pages/LogoutScreen'));

const Dashboard = lazyWithRetry(
  () => import('../../../../dashboard/src/Router'),
);

const Academy = lazyWithRetry(
  () => import('../../../../common/src/components/academy/Academy'),
);

const ThankYouPage = lazyWithRetry(
  () => import('../../../../common/src/components/ThankYouPage'),
);

const ProFlowRouter = lazyWithRetry(
  () => import('../../../../tarifications/src/legacy/pages/ProFlowRouter'),
);

const CRMRouter = lazyWithRetry(
  () => import('../../../../callant/src/CRMRouter'),
);
const CustomerRouter = lazyWithRetry(
  () =>
    import('../../../../callant/src/features/public-customers/CustomerRouter'),
);
const NotificationCenter = lazyWithRetry(
  () => import('../notifications/components/NotificationCenter'),
);

const ClaimsRouter = lazyWithRetry(
  () => import('../../../../claims/src/Router'),
);
const ClaimsAdminRouter = lazyWithRetry(
  () => import('../../../../claims-admin/src/Router'),
);
const ToolkitRouter = lazyWithRetry(
  () => import('../../../../toolkit/src/Router'),
);
const IntegrationCallBacks = lazyWithRetry(
  () => import('../../pages/IntegrationCallBacks'),
);
const AdvisoryReportRouter = lazyWithRetry(
  () => import('../../../../advisory-report/src/Router'),
);
const TrialPricing = lazyWithRetry(
  () => import('../../../../common/src/components/TrialPricing'),
);
const Campaigns = lazyWithRetry(
  () => import('../../../../campaigns/src/Router'),
);

const Settings = lazyWithRetry(() => import('../../../../settings/src/Router'));

const Conversations = lazyWithRetry(
  () => import('../../../../conversations/src/Router'),
);

const Parties = lazyWithRetry(() => import('../../../../parties/src/Router'));

const Offers = lazyWithRetry(() => import('../../../../offers/src/Router'));

const MainRouter: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();
  const {
    isAuthorized,
    isAllBrokerDataFetched,
    isAllSocialUserDataFetched,
    isAuthenticatingViaLoginToken,
    isAllConversationsDataFetched,
  } = useAuth();
  const { pathname } = useLocation();
  const { isInsuranceTermsBotEnabled } = useFeatureFlags();

  if (isCampaignsUrl) {
    if (!isAllSocialUserDataFetched) {
      return <LoadingPage />;
    }
  } else if (
    isConversationUrl ||
    isLouiseQAUrl ||
    (isFinalizeUrl && isSocialLoginUrl)
  ) {
    if (!isAllConversationsDataFetched) {
      return <LoadingPage />;
    }
  } else if (isAuthorized && !isAllBrokerDataFetched) {
    return <LoadingPage />;
  } else if (isAuthenticatingViaLoginToken) {
    return <LoadingPage />;
  }

  // TODO: Remove when migration to react router v6 is done
  return (
    <Suspense fallback={<LoadingPage />}>
      <TopNavigationItemsProvider>
        <ConditionalProviders>
          <AppLoadingDone />
          <Switch>
            {!isSocialLoginUrl && <Redirect from="/" to="/dashboard" exact />}
            <Route path="/login" component={Authentication} exact />
            <Route path="/logout" component={LogoutScreen} exact />

            <Route
              path="/"
              render={() => {
                return (
                  <AppShell
                    nav={
                      <Suspense fallback={<NavigationLoading />}>
                        <Route
                          path={[
                            '/dashboard',
                            '/conversation',
                            '/professional-flow',
                            '/claims-management',
                            '/toolkit',
                            '/trial-pricing',
                            '/sales-management',
                            '/broker-history',
                            '/campaigns',
                            '/settings',
                            '/notifications',
                            '/conversation-history',
                            '/advisory-report',
                            '/advisory-report-legacy',
                            '/callant',
                            '/academy',
                            '/conversations',
                            '/parties',
                            '/offers',
                            '/louiseqa',
                            '/yellowhive',
                          ]}
                          render={() => (
                            <BrokerRoute noLoading>
                              <Navigation />
                            </BrokerRoute>
                          )}
                        />
                      </Suspense>
                    }
                  >
                    <Switch>
                      {/* PUBLIC routes */}

                      <Redirect from="/session/broker" to="/product/session" />
                      <Route
                        path="/integrations"
                        component={IntegrationCallBacks}
                      />
                      <Route
                        path="/thank-you-for-signing"
                        component={ThankYouPage}
                      />
                      <Route
                        path="/aska-travel/finalize"
                        render={() => (
                          <SocialRoute>
                            <AskaFinalize />
                          </SocialRoute>
                        )}
                      />
                      <Route
                        path={'/client-overview'}
                        component={CustomerRouter}
                      />
                      <Route path="/claims" component={ClaimsRouter} />

                      <Route
                        path="/company-scan/already-completed"
                        render={() => (
                          <SocialRoute>
                            <CampaignsFinalizationView
                              customTitle={t(
                                'FLOWS_ASK-ALTER_ALREADY-COMPLETE_TITLE',
                              )}
                            />
                          </SocialRoute>
                        )}
                        exact
                      />

                      {/** SOCIAL ROUTES */}
                      <Route
                        path={[
                          '/product',
                          '/flows/:flowId',
                          '/risk-analysis',
                          '/company-scan',
                          '/sales-conversation',
                        ]}
                        render={() =>
                          isSocialLoginUrl ? (
                            <Flows />
                          ) : (
                            <BrokerRoute>
                              <Flows />
                            </BrokerRoute>
                          )
                        }
                      />

                      <Route
                        path={'/louise'}
                        render={() => (
                          <SocialRoute>
                            <InsuranceTermsBot />
                          </SocialRoute>
                        )}
                      />
                      {isSocialLoginUrl && <CampaignRedirector />}

                      {/* BROKER ROUTES */}

                      {/* START LEGACY ROUTES REDIRECTS */}

                      <Redirect
                        from="/toolkit/insurance-terms-bot"
                        to="/toolkit/louiseqa"
                      />
                      <Redirect
                        from="/conversation-history/proxy"
                        to={pathname.replace(
                          '/conversation-history/proxy',
                          '/conversations',
                        )}
                      />
                      <Redirect
                        from="/conversation-history"
                        to="/conversations"
                      />

                      {/* END LEGACY ROUTES REDIRECTS */}
                      <Route
                        path={'/claims-management'}
                        render={() => (
                          <BrokerRoute>
                            <Can I="use" a="claims">
                              <Suspense fallback={<ClaimsLoading />}>
                                <ClaimsAdminRouter />
                              </Suspense>
                            </Can>
                          </BrokerRoute>
                        )}
                      />

                      <Route
                        path={['/dashboard', '/conversation']}
                        render={() => (
                          <BrokerRoute>
                            <Dashboard />
                          </BrokerRoute>
                        )}
                      />

                      <Route
                        path={'/academy'}
                        render={() => (
                          <BrokerRoute>
                            <Suspense fallback={<LoadingSpinner />}>
                              <Academy />
                            </Suspense>
                          </BrokerRoute>
                        )}
                        exact
                      />

                      <Route
                        path={'/toolkit'}
                        render={() => (
                          <BrokerRoute>
                            <Suspense fallback={<ToolkitLoading />}>
                              <Can I="use" a="toolkit" passThrough>
                                {(allowed) =>
                                  allowed || isInsuranceTermsBotEnabled ? (
                                    <ToolkitRouter />
                                  ) : (
                                    <Can I="use" a="louise_qa">
                                      <ToolkitRouter />
                                    </Can>
                                  )
                                }
                              </Can>
                            </Suspense>
                          </BrokerRoute>
                        )}
                      />

                      <Route
                        path={'/trial-pricing'}
                        render={() => (
                          <BrokerRoute>
                            <TrialPricing />
                          </BrokerRoute>
                        )}
                      />

                      <Route
                        path={'/professional-flow'}
                        render={() => (
                          <BrokerRoute>
                            <Can I="use" a="proflow">
                              <Suspense fallback={<ProflowLoading />}>
                                <ProFlowRouter />
                              </Suspense>
                            </Can>
                          </BrokerRoute>
                        )}
                      />

                      <Route
                        path={'/sales-management'}
                        render={() => (
                          <BrokerRoute>
                            <Can I="use" a="portfolio">
                              <Suspense fallback={<LoadingPage />}>
                                <CRMRouter />
                              </Suspense>
                            </Can>
                          </BrokerRoute>
                        )}
                      />
                      <Route
                        path={'/settings'}
                        render={() => (
                          <BrokerRoute>
                            <Suspense fallback={<LoadingPage />}>
                              <Settings />
                            </Suspense>
                          </BrokerRoute>
                        )}
                      />
                      <Route
                        path="/notifications"
                        render={() => (
                          <BrokerRoute>
                            <Suspense fallback={<NotificationCenterLoading />}>
                              <NotificationCenter />
                            </Suspense>
                          </BrokerRoute>
                        )}
                        exact
                      />
                      <Route
                        path={['/advisory-report', '/advisory-report-legacy']}
                        render={() => (
                          <BrokerRoute>
                            <Can I="use" a="advisory_reports">
                              <Suspense fallback={<LoadingPage />}>
                                <AdvisoryReportRouter />
                              </Suspense>
                            </Can>
                          </BrokerRoute>
                        )}
                      />

                      <Route
                        path={'/campaigns'}
                        render={() => (
                          <Can I="use" a="campaigns">
                            <Suspense fallback={<LoadingPage />}>
                              <Campaigns />
                            </Suspense>
                          </Can>
                        )}
                      />

                      <Route
                        path={'/conversations'}
                        render={() => (
                          <BrokerRoute>
                            <Suspense fallback={<LoadingPage />}>
                              <Conversations />
                            </Suspense>
                          </BrokerRoute>
                        )}
                      />

                      <Route
                        path={'/parties'}
                        render={() => (
                          <BrokerRoute>
                            <Suspense fallback={<LoadingPage />}>
                              <Parties />
                            </Suspense>
                          </BrokerRoute>
                        )}
                      />
                      <Route
                        path={'/yellowhive'}
                        render={() => (
                          <BrokerRoute>
                            <Suspense fallback={<LoadingPage />}>
                              <YellowHiveRouter />
                            </Suspense>
                          </BrokerRoute>
                        )}
                      />
                      <Route
                        path={'/offers'}
                        render={() => (
                          <BrokerRoute>
                            <Suspense fallback={<LoadingPage />}>
                              <Offers />
                            </Suspense>
                          </BrokerRoute>
                        )}
                      />

                      <Route
                        path={'/louiseqa'}
                        render={() => (
                          <BrokerRoute>
                            <Suspense fallback={<LoadingPage />}>
                              <InsuranceTermsBot />
                            </Suspense>
                          </BrokerRoute>
                        )}
                      />

                      <Route path="/" render={() => <RouteNotFoundPage />} />
                    </Switch>
                  </AppShell>
                );
              }}
            />
          </Switch>
        </ConditionalProviders>
      </TopNavigationItemsProvider>
    </Suspense>
  );
};

export default MainRouter;
