import React from 'react';
import { Helmet } from 'react-helmet';
import useFeatureFlags from '../../../app/src/hooks/useFeatureFlags';

const FavIcons: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { isYellowHiveEnabled } = useFeatureFlags();

  if (isYellowHiveEnabled) {
    return (
      <Helmet>
        <link
          rel="icon"
          sizes="57x57"
          href="https://yellowhive.com/application/files/6616/7171/0578/bookmark-yellow-hive-32.png"
        />
        <link
          rel="favicon"
          type="image/png"
          href="https://yellowhive.com/application/files/6616/7171/0578/bookmark-yellow-hive-32.png"
          sizes="128x128"
        />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <link
        rel="apple-touch-icon-precomposed"
        sizes="57x57"
        href={'/icons/apple-touch-icon-57x57.png'}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href={'/icons/apple-touch-icon-114x114.png'}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href={'/icons/apple-touch-icon-72x72.png'}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href={'/icons/apple-touch-icon-144x144.png'}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="60x60"
        href={'/icons/apple-touch-icon-60x60.png'}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href={'/icons/apple-touch-icon-120x120.png'}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="76x76"
        href={'/icons/apple-touch-icon-76x76.png'}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href={'/icons/apple-touch-icon-152x152.png'}
      />
      <link
        rel="icon"
        type="image/png"
        href={'/icons/favicon-196x196.png'}
        sizes="196x196"
      />
      <link
        rel="icon"
        type="image/png"
        href={'/icons/favicon-96x96.png'}
        sizes="96x96"
      />
      <link
        rel="icon"
        type="image/png"
        href={'/icons/favicon-32x32.png'}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href={'/icons/favicon-16x16.png'}
        sizes="16x16"
      />
      <link
        rel="favicon"
        type="image/png"
        href={'/icons/favicon-128.png'}
        sizes="128x128"
      />
    </Helmet>
  );
};

export default FavIcons;
