import {
  FormControl,
  FormLabel,
  SearchSelect,
  FormErrorMessage,
} from '@wegroup/design-system';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { City, useCitySearch } from '../../../api';
import useDebouncedSearchSelectQuery from '../../../hooks/useDebouncedQuery';
import useNothingFoundText from '../../../hooks/useNothingFoundText';

export interface CitySearchProps {
  name?: string;
  value?: string;
  countryCode?: string;
  onChange: (city?: string, zipcode?: string | number) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: FieldError;
  isDisabled?: boolean;
  isPrefilled?: boolean;
}

const CitySearch: React.FC<CitySearchProps> = ({
  name,
  value,
  countryCode,
  onChange,
  onBlur,
  onFocus,
  error,
  isDisabled,
  isPrefilled,
}) => {
  const { t } = useTranslation();

  const { query, handleSearchChange, cancelDebouncedSetQuery } =
    useDebouncedSearchSelectQuery();

  const { data: citiesList, isLoading } = useCitySearch({
    query: query.toLowerCase(),
    countryCode,
  });

  const nothingFoundText = useNothingFoundText({
    isLoading,
    query,
  });

  const getCityOptions = (citiesList?: City[]) => {
    if (citiesList) {
      return citiesList?.map((city) => city.name);
    }

    if (value) {
      return [value];
    }

    return [];
  };

  const handleChange = (city: string | null) => {
    const cityObject = citiesList?.find(({ name }) => name === city);
    onChange(city || undefined, city ? cityObject?.zipcode : undefined);
    cancelDebouncedSetQuery();
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{t('CITY-OR-MUNICIPALITY')}</FormLabel>
      <SearchSelect
        name={name}
        data={getCityOptions(citiesList)}
        isLoading={isLoading}
        value={value || null}
        onChange={handleChange}
        onSearchChange={handleSearchChange}
        nothingFound={nothingFoundText}
        isDisabled={isDisabled}
        prefilled={value != null && isPrefilled}
        onBlur={onBlur}
        onFocus={onFocus}
        clearable
        isDataFilterDisabled
      />
      {error?.message && (
        <FormErrorMessage>{t(error.message)}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default CitySearch;
