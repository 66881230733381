const yellowhive = {
  primary: {
    main: '#f9b00f',
    50: '#fffceb',
    100: '#fff7c6',
    150: '#fff2a7',
    200: '#ffee88',
    300: '#ffe04a',
    400: '#fece21',
    500: '#f9b00f',
    600: '#dc8403',
    700: '#b75e06',
    750: '#a55207',
    800: '#94470c',
    850: '#7a3c0d',
    900: '#461e02',
  },
  secondary: {
    main: '#1b3158',
    '50': '#dceefd',
    '100': '#97d2f9',
    '150': '#65b9f5',
    '200': '#429bef',
    '300': '#2c7ee4',
    '400': '#2468d1',
    '500': '#1b3158',
    '600': '#17294a',
    '700': '#14223d',
    '750': '#111d34',
    '800': '#0f182b',
    '850': '#0c1422',
    '900': '#0a1019',
  },
};

export default yellowhive;
