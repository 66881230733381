import React from 'react';
import { Icon, Box, useTheme } from '@wegroup/design-system';

import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import fr from 'react-phone-number-input/locale/fr.json';
import nl from 'react-phone-number-input/locale/nl.json';
import { useTranslation } from 'react-i18next';

import { CountryCode } from 'libphonenumber-js/core';
import Flag from '../CountrySelectWithFlag/Flag';

export interface PhoneInputProps {
  name?: string;
  placeholder?: string;
  value?: string;
  onChange: (e: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  defaultCountry: CountryCode;
  language: string;
}

const PhoneInput: React.FC<React.PropsWithChildren<PhoneInputProps>> = ({
  onChange,
  value = '',
  onBlur,
  placeholder,
  name,
  isDisabled,
  language,
  defaultCountry,
  ...otherProps
}) => {
  const { colors, space } = useTheme();
  const { t } = useTranslation();

  const labelLang = {
    nl,
    fr,
    en,
  };

  return (
    <Box
      sx={{
        '.PhoneInput': {
          bg: 'white',
          overflow: 'hidden',
          height: '40px',
          borderRadius: '6px',
          border: `1px solid ${colors.gray[100]}`,
        },
        '.PhoneInputCountry, .PhoneInputInput': {
          height: '100%',
        },

        '.PhoneInputCountry': {
          margin: 0,
          paddingLeft: space[2],
          paddingRight: space[1],
          bg: 'white',
          borderRight: '1px solid',
          borderColor: colors.gray[100],
        },
        '.PhoneInputCountryIcon': {
          borderRadius: '999999px',
          width: space[6],
          height: space[6],
          overflow: 'hidden',
          position: 'cover',
          marginRight: space[1],
          border: 'none',
          boxShadow: 'none',
        },
        '.PhoneInputCountryIconImg': {
          objectFit: 'cover',
        },
        '.PhoneInputInput': {
          pl: '0.8rem',
          '&:focus': {
            outline: colors.primary[500],
          },
        },
      }}
    >
      <Input
        name={name}
        placeholder={placeholder || t('Enter phone number')}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        defaultCountry={defaultCountry || 'BE'}
        international
        labels={labelLang[language] || en}
        withCountryCallingCode
        flagComponent={(props) => <Flag country={props.country} />}
        countrySelectProps={{
          arrowComponent: () => (
            <Icon name="GeneralActionsCaretDown" color="gray.300" />
          ),
        }}
        disabled={isDisabled}
        {...otherProps}
      />
    </Box>
  );
};

export default PhoneInput;
