import { ChevronRight } from 'lucide-react';
import React from 'react';
import { Product } from '../../types/yellowHiveTypes';

interface Props {
  product: Product;
  onClick: () => void;
}

const ProductListItem: React.FC<Props> = ({ product, onClick }) => {
  return (
    <div className="product-item">
      <p className="product-link" onClick={onClick}>
        <span>{product.name}</span>
        <ChevronRight className="chevron-icon" />
      </p>
    </div>
  );
};

export default ProductListItem;
