import React, { PropsWithChildren, ReactElement } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerGeneralHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerProps,
  BoxProps,
} from '@wegroup/design-system';

interface Props {
  rightElement?: ReactElement;
  title: string;
  description?: string;
  icon?: ReactElement;
  bodyProps?: BoxProps;
}

const EntityDrawer: React.FC<PropsWithChildren & DrawerProps & Props> = ({
  rightElement,
  title,
  description,
  icon,
  children,
  bodyProps,
  ...props
}) => {
  return (
    <Drawer placement="right" size="lg" {...props} trapFocus={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerGeneralHeader
          title={title}
          description={description}
          icon={icon}
          rightElement={rightElement}
        />
        <DrawerBody {...bodyProps}>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default EntityDrawer;
