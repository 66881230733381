import { Section } from '../types/yellowHiveTypes';

export const bedrijfsmatigSections: Section[] = [
  {
    id: 'verkeer',
    title: 'Verkeer & Vervoer',
    iconType: 'Car',
    products: [
      {
        id: 'aanhangwagenverzekering-zakelijk',
        name: 'Aanhangwagenverzekering',
        category: 'Bedrijfsmatig',
        section: 'verkeer',
        tags: ['transport', 'koeriersdienst'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'autoverzekering-zakelijk',
        name: 'Autoverzekering',
        category: 'Bedrijfsmatig',
        section: 'verkeer',
        tags: ['transport', 'taxibedrijf'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'bestelautoverzekering-zakelijk',
        name: 'Bestelautoverzekering',
        category: 'Bedrijfsmatig',
        section: 'verkeer',
        tags: ['transport', 'koeriersdienst', 'mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'eigen-vervoerverzekering',
        name: 'Eigen Vervoerverzekering',
        category: 'Bedrijfsmatig',
        section: 'verkeer',
        tags: ['transport'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'goederentransportverzekering',
        name: 'Goederentransportverzekering',
        category: 'Bedrijfsmatig',
        section: 'verkeer',
        tags: ['transport', 'koeriersdienst'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'landbouwmaterieelverzekering',
        name: 'Landbouwmaterieelverzekering',
        category: 'Bedrijfsmatig',
        section: 'verkeer',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'verkeersschadeverzekering',
        name: 'Verkeersschadeverzekering',
        category: 'Bedrijfsmatig',
        section: 'verkeer',
        tags: ['transport', 'taxibedrijf'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'vrachtautoverzekering',
        name: 'Vrachtautoverzekering',
        category: 'Bedrijfsmatig',
        section: 'verkeer',
        tags: ['transport'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'wagenparken',
        name: 'Wagenparken',
        category: 'Bedrijfsmatig',
        section: 'verkeer',
        tags: ['transport', 'taxibedrijf'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'werkmaterieel',
        name: 'Werkmaterieel',
        category: 'Bedrijfsmatig',
        section: 'verkeer',
        tags: ['mkb', 'offshore'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'zakenreisverzekering',
        name: 'Zakenreisverzekering',
        category: 'Bedrijfsmatig',
        section: 'verkeer',
        tags: ['mkb', 'zzp'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
    ],
  },
  {
    id: 'bedrijfsmiddelen',
    title: 'Bedrijfsmiddelen & Continuïteit',
    iconType: 'Building2',
    products: [
      {
        id: 'bedrijfsgebouwenverzekering',
        name: 'Bedrijfsgebouwenverzekering',
        category: 'Bedrijfsmatig',
        section: 'bedrijfsmiddelen',
        tags: ['mkb', 'vastgoed'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'bedrijfsschadeverzekering',
        name: 'Bedrijfsschadeverzekering',
        category: 'Bedrijfsmatig',
        section: 'bedrijfsmiddelen',
        tags: ['mkb', 'zzp'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'computer-electronica',
        name: 'Computer- en Electronicaverzekering',
        category: 'Bedrijfsmatig',
        section: 'bedrijfsmiddelen',
        tags: ['mkb', 'media'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'cyberverzekering',
        name: 'Cyberverzekering',
        category: 'Bedrijfsmatig',
        section: 'bedrijfsmiddelen',
        tags: ['mkb', 'zzp'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'exploitatiekostenverzekering',
        name: 'Exploitatiekostenverzekering',
        category: 'Bedrijfsmatig',
        section: 'bedrijfsmiddelen',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'extra-kosten',
        name: 'Extra kosten verzekering',
        category: 'Bedrijfsmatig',
        section: 'bedrijfsmiddelen',
        tags: ['mkb', 'zzp'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'geldverzekering',
        name: 'Geldverzekering',
        category: 'Bedrijfsmatig',
        section: 'bedrijfsmiddelen',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'glasverzekering-zakelijk',
        name: 'Glasverzekering Zakelijk',
        category: 'Bedrijfsmatig',
        section: 'bedrijfsmiddelen',
        tags: ['mkb', 'vastgoed'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'inventaris-goederen',
        name: 'Inventaris- goederenverzekering',
        category: 'Bedrijfsmatig',
        section: 'bedrijfsmiddelen',
        tags: ['mkb', 'zzp'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'milieuschade',
        name: 'Milieuschade',
        category: 'Bedrijfsmatig',
        section: 'bedrijfsmiddelen',
        tags: ['mkb', 'offshore', 'windmolens'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
    ],
  },
  {
    id: 'aansprakelijkheid',
    title: 'Aansprakelijkheid & Juridisch',
    iconType: 'Scale',
    products: [
      {
        id: 'aansprakelijkheid-onroerend-goed',
        name: 'Aansprakelijkheidsverzekering onroerend goed',
        category: 'Bedrijfsmatig',
        section: 'aansprakelijkheid',
        tags: ['vastgoed', 'vve'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'bedrijfsaansprakelijkheid',
        name: 'Bedrijfsaansprakelijkheidsverzekering',
        category: 'Bedrijfsmatig',
        section: 'aansprakelijkheid',
        tags: ['mkb', 'zzp'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'beroepsaansprakelijkheid',
        name: 'Beroepsaansprakelijkheidsverzekering',
        category: 'Bedrijfsmatig',
        section: 'aansprakelijkheid',
        tags: ['zzp'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'bestuurdersaansprakelijkheid',
        name: 'Bestuurdersaansprakelijkheidsverzekering',
        category: 'Bedrijfsmatig',
        section: 'aansprakelijkheid',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'car-verzekering',
        name: 'CAR verzekering',
        category: 'Bedrijfsmatig',
        section: 'aansprakelijkheid',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'montageverzekering',
        name: 'Montageverzekering',
        category: 'Bedrijfsmatig',
        section: 'aansprakelijkheid',
        tags: ['mkb', 'windmolens'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'rechtsbijstand-bedrijven',
        name: 'Rechtsbijstandverzekering Bedrijven',
        category: 'Bedrijfsmatig',
        section: 'aansprakelijkheid',
        tags: ['mkb', 'zzp'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'rechtsbijstand-motorrijtuigen',
        name: 'Rechtsbijstandverzekering Motorrijtuigen',
        category: 'Bedrijfsmatig',
        section: 'aansprakelijkheid',
        tags: ['transport', 'taxibedrijf'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
    ],
  },
  {
    id: 'inkomen',
    title: 'Inkomen & Financiële Zekerheid',
    iconType: 'Wallet',
    products: [
      {
        id: 'arbodienstverlening',
        name: 'Arbodienstverlening',
        category: 'Bedrijfsmatig',
        section: 'inkomen',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'arbeidsongeschiktheid',
        name: 'Arbeidsongeschiktheidsverzekering',
        category: 'Bedrijfsmatig',
        section: 'inkomen',
        tags: ['zzp'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'collectieve-ongevallen',
        name: 'Collectieve ongevallenverzekering',
        category: 'Bedrijfsmatig',
        section: 'inkomen',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'compagnonsverzekering',
        name: 'Compagnonsverzekering',
        category: 'Bedrijfsmatig',
        section: 'inkomen',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'ondernemershypotheek',
        name: 'Ondernemershypotheek',
        category: 'Bedrijfsmatig',
        section: 'inkomen',
        tags: ['zzp'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'pensioen-werkgever',
        name: 'Pensioen via werkgever',
        category: 'Bedrijfsmatig',
        section: 'inkomen',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'pensioen-ondernemer',
        name: 'Pensioen ondernemer',
        category: 'Bedrijfsmatig',
        section: 'inkomen',
        tags: ['zzp'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'personeelsverzekeringen',
        name: 'Personeelsverzekeringen',
        category: 'Bedrijfsmatig',
        section: 'inkomen',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'schadeverzekering-werknemers',
        name: 'Schadeverzekering werknemers',
        category: 'Bedrijfsmatig',
        section: 'inkomen',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'verzuimverzekering',
        name: 'Verzuimverzekering',
        category: 'Bedrijfsmatig',
        section: 'inkomen',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'wga-verzekering',
        name: 'WGA-verzekering',
        category: 'Bedrijfsmatig',
        section: 'inkomen',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
      {
        id: 'wia-verzekering',
        name: 'WIA-verzekering',
        category: 'Bedrijfsmatig',
        section: 'inkomen',
        tags: ['mkb'],
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://example.com/logos/aanhangwagen.png',
      },
    ],
  },
];
