import React from 'react';
import { Input, InputProps, InputGroup, Select } from '@wegroup/design-system';
import NumberFormat from 'react-number-format';
import {
  CompanyRegistration,
  CompanyRegistrationType,
} from '../../../../../parties/src/types';
import { useLocale } from '../../../../../../i18n';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: CompanyRegistration;
  onChange: (value: CompanyRegistration) => void;
  defaultCountry?: string;
}

export const companyNumberFormats: Record<
  CompanyRegistrationType,
  { mask?: string; country: string; regex: string }
> = {
  KVK: {
    mask: '########',
    country: 'NL',
    regex: '^\\d{8}$',
  },
  CBE: {
    mask: '####.###.###',
    country: 'BE',
    regex: '^\\d{4}\\.\\d{3}\\.\\d{3}$',
  },
  KVK_AW: {
    mask: 'H#####.#',
    country: 'AW',
    regex: '^H\\d{5}\\.\\d$',
  },
  SIREN: {
    mask: '#########',
    country: 'FR',
    regex: '^\\d{9}$',
  },
  SIRET: {
    mask: '###########',
    country: 'FR',
    regex: '^\\d{14}$',
  },
  HRN: {
    mask: 'HR####',
    country: 'DE',
    regex: '^HR\\d{4}$',
  },
  KVK_BQ: {
    mask: 'H#####.#',
    country: 'BQ',
    regex: '^H\\d{5}\\.\\d$',
  },
  KVK_SX: {
    mask: 'H#####.#',
    country: 'SX',
    regex: '^H\\d{5}\\.\\d$',
  },
  KVK_CW: {
    mask: 'H#####.#',
    country: 'CW',
    regex: '^H\\d{5}\\.\\d$',
  },
};

export const checkCompanyNumberFormat = (inputNumber: string) => {
  for (const format of Object.keys(
    companyNumberFormats,
  ) as CompanyRegistrationType[]) {
    const regex = companyNumberFormats[format].regex;
    if (inputNumber.match(regex)) {
      return format;
    }
  }
};

export const getTypeForCountry = (
  country: string,
): CompanyRegistrationType | undefined =>
  Object.entries(companyNumberFormats).find(
    ([, { country: c }]) => c === country,
  )?.[0] as CompanyRegistrationType;

const CompanyNumberInput: React.FC<
  React.PropsWithChildren<Props & Omit<InputProps, 'value'>>
> = ({ value, onChange, defaultCountry, ...props }) => {
  const { userCountry } = useLocale();
  const defaultType = getTypeForCountry(defaultCountry || userCountry);

  const handleTypeChange = (type: CompanyRegistrationType) => {
    if (type) {
      onChange({ ...value, type });
    }
  };

  return (
    <InputGroup>
      <CompanyNumberInputSelect
        value={value?.type || defaultType}
        onChange={handleTypeChange}
      />
      <Input
        as={NumberFormat}
        // format={companyNumberFormats[value?.type || defaultType || '']?.mask}
        defaultValue={value?.nr}
        value={value?.nr}
        onChange={(e) => {
          const inputValue = e.target.value; // Get the raw input value
          onChange?.({ type: value?.type || defaultType, nr: inputValue }); // Set the unformatted value
        }}
        // onValueChange={(values: NumberFormatValues) => {
        //   const { formattedValue } = values;
        //   if (value?.type) {
        //     onChange?.({ ...value, nr: formattedValue.replaceAll('.', '') });
        //   }
        // }}
        borderLeftRadius="none"
        // allowEmptyFormatting
        {...props}
      />
    </InputGroup>
  );
};

interface SelectProps {
  value?: CompanyRegistrationType;
  onChange: (type: CompanyRegistrationType) => void;
}

export const CompanyNumberInputSelect: React.FC<
  React.PropsWithChildren<SelectProps>
> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const registrationTypes = Object.keys(
    companyNumberFormats,
  ) as CompanyRegistrationType[];

  return (
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value as CompanyRegistrationType)}
      borderRightColor="rgba(0,0,0,0)"
      borderTopRightRadius="none"
      width="72"
      borderBottomRightRadius="none"
    >
      {registrationTypes.map((type) => (
        <option key={type} value={type}>
          {t(`COMPANY_NR_TYPE_${type}`)}
        </option>
      ))}
    </Select>
  );
};

export default CompanyNumberInput;
