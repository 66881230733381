import GeneralPageLayout from '@common/src/components/GeneralPageLayout';
import { Collapse, Text } from '@wegroup/design-system';
import React, { useState } from 'react';
import { CategoryTabs } from '../components/market/CategoryTabs';
import { ProductList } from '../components/market/ProductList';
import { SearchBar } from '../components/market/SearchBar';
import { TagFilter } from '../components/market/TagFilter';
import { sections, tags } from '../data/mockdata';
import { ProductCategory } from '../types/yellowHiveTypes';
import '../yellowHiveCss.css';

const YellowHiveMarket: React.FC = () => {
  const [category, setCategory] = useState<ProductCategory>('Particulier');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const handleTagToggle = (tagId: string) => {
    setSelectedTags((prev) =>
      prev.includes(tagId)
        ? prev.filter((id) => id !== tagId)
        : [...prev, tagId],
    );
  };

  return (
    <GeneralPageLayout>
      <div className="container">
        <Text textStyle="h1Headline" align="center">
          Yellow Hive Marktplaats
        </Text>
        <Text
          textStyle="normalBody"
          align="center"
          maxW="4xl"
          mx="auto"
          mb="6"
          mt="3"
          color="gray.500"
        >
          Snel en eenvoudig producten vinden door te zoeken op sector, product
          of beschrijving. Klik op een product om direct naar de bijbehorende
          pagina te gaan.
        </Text>

        <CategoryTabs
          activeCategory={category}
          onCategoryChange={setCategory}
        />

        <SearchBar value={searchTerm} onChange={setSearchTerm} />

        <Collapse in={category === 'Bedrijfsmatig'} animateOpacity>
          <TagFilter
            tags={tags}
            selectedTags={selectedTags}
            onTagToggle={handleTagToggle}
          />
        </Collapse>

        <ProductList
          sections={sections}
          category={category}
          searchTerm={searchTerm}
          selectedTags={selectedTags}
        />
      </div>
    </GeneralPageLayout>
  );
};

export default YellowHiveMarket;
