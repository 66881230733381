import { useEffect } from 'react';

interface MoveElementProps {
  element: HTMLElement | null;
  paddingBottom?: number;
  paddingRight?: number;
}

const useMoveElement = ({
  element,
  paddingBottom = 0,
  paddingRight = 0,
}: MoveElementProps): void => {
  const moveComponent = () => {
    if (element) {
      element.style.setProperty('right', `${paddingRight}px`, 'important');
      element.style.setProperty('bottom', `${paddingBottom}px`, 'important');
    }
  };

  const resetComponentPosition = () => {
    if (element) {
      element.style.setProperty('right', `${paddingRight}px`);
      element.style.setProperty('bottom', `${paddingBottom}px`);
    }
  };

  if (paddingBottom > 0) moveComponent();

  useEffect(() => {
    return () => {
      resetComponentPosition();
    };
  }, []);
};

export default useMoveElement;
