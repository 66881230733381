import React from 'react';
import { Center, Image } from '@wegroup/design-system';
import { Helmet } from 'react-helmet';
import { Switch, Route, Redirect } from 'react-router';
import ErrorBoundary from '../../app/src/components/ErrorBoundary';
import YellowHiveMarket from './pages/YellowHiveMarket';

export const ROUTE_SRC = '/yellowhive';

const YellowHiveRouter: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <ErrorBoundary>
      <Helmet>
        <title>Yellow Hive</title>
      </Helmet>
      <Switch>
        <Redirect from={ROUTE_SRC} to={`${ROUTE_SRC}/market`} exact />
        <Route
          path={`${ROUTE_SRC}/market`}
          component={YellowHiveMarket}
          exact
        />
        <Route
          path={`${ROUTE_SRC}`}
          component={() => (
            <Center h="100%">
              <Image
                cursor="pointer"
                src={
                  'https://yellowhive.com/application/files/2116/6626/7789/logo-yellow-hive.svg'
                }
                alt={`Yellowhive logo`}
                width="100%"
                maxW="xs"
                height="100%"
                objectFit="contain"
                loading="eager"
              />
            </Center>
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default YellowHiveRouter;
