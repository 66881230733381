import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@wegroup/design-system';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { compareProps } from '../../../../../../../tarifications/src/legacy/services/proFlowService';
import { prefilledInputComponentStyling } from '../../../../../../../flows/src/constants/InputComponentStyling';

interface Props {
  name?: string;
  value?: string;
  onChange: (houseNumber?: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: FieldError;
  isDisabled?: boolean;
  isPrefilled?: boolean;
}

const HouseNumber: React.FC<Props> = ({
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  isDisabled,
  isPrefilled,
  error,
}) => {
  const { t } = useTranslation();

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{t('HOUSENUMBER')}</FormLabel>
      <Input
        name={name}
        value={value || ''}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        isDisabled={isDisabled}
        {...(value != null &&
          isPrefilled && { sx: prefilledInputComponentStyling })}
      />
      {error?.message && (
        <FormErrorMessage>{t(error.message)}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default React.memo(HouseNumber, compareProps);
