import { Center, Text } from '@wegroup/design-system';
import React from 'react';
import ErrorPageSVG from '../../../../common/src/assets/images/ErrorPageSvg';
import i18n from '../../../../../i18n';

interface Props {
  isCampaign?: boolean;
  fullHeight?: boolean;
}

const ErrorPage: React.FC<React.PropsWithChildren<Props>> = ({
  isCampaign = false,
  fullHeight = true,
}) => {
  /**
   * Rare occasions have occured where i18n was undefined.
   * As a quick fix, this function will check if it is defined
   * to make sure that the error page itself does not crash
   * @param translationString The string to be translated
   * @returns A translated string if i18n is defined
   */
  const showTranslationIfI18n = (translationString: string) =>
    i18n ? i18n.t(translationString) : translationString;

  return (
    <Center w="100%" h={fullHeight ? '100vh' : 'auto'} flexDirection="column">
      <ErrorPageSVG />
      <Text mt="8" textStyle="h2Headline">
        {showTranslationIfI18n('Something went wrong')}
      </Text>
      {isCampaign && (
        <Text mt="2">{showTranslationIfI18n('Contact your broker')}.</Text>
      )}
    </Center>
  );
};

export default ErrorPage;
