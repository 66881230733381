import React, { useRef } from 'react';
import {
  HStack,
  Tooltip,
  Icon,
  IconButton,
  Text,
  useClipboard,
  Box,
} from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';
import { chakraCustomScrollBar } from '../../../../../common/src/utils/styledComponentsUtils';

interface Props {
  onCopy?: () => void;
  wrap?: boolean;
  variant?: 'default' | 'small';
}

const ClipboardTextBox: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onCopy = () => null,
  wrap,
  variant = 'default',
  ...props
}) => {
  const { t } = useTranslation();
  const textRef = useRef<HTMLParagraphElement>(null);
  const { onCopy: onCopyClipboard, hasCopied } = useClipboard(
    children?.toString() || '',
  );

  const handleClick = () => {
    if (textRef.current) {
      const selection = window.getSelection();
      selection?.removeAllRanges();
      const range = document.createRange();
      range.selectNodeContents(textRef.current);
      selection?.addRange(range);
    }
  };

  return (
    <HStack
      justify="space-between"
      bg="white"
      border="1px solid"
      borderColor="gray.100"
      p="1"
      pl="2"
      spacing="2"
      borderRadius="md"
      // minWidth 0 fixes flex box issue with whiteSpace change
      minWidth="0"
      onClick={handleClick}
      {...props}
    >
      <Box overflowX="auto" sx={chakraCustomScrollBar}>
        <Text
          fontFamily="Courier"
          fontSize="sm"
          flexGrow={1}
          whiteSpace={wrap ? 'unset' : 'nowrap'}
          ref={textRef}
        >
          {children}
        </Text>
      </Box>

      <Tooltip placement="left" label={t('Copy to clipboard')}>
        <IconButton
          alignSelf="flex-start"
          variant="grayed"
          aria-label="Copy to clipboard"
          size={variant === 'small' ? 'sm' : 'md'}
          onClick={(e) => {
            e.stopPropagation();
            onCopy?.();
            onCopyClipboard();
          }}
          {...(hasCopied && {
            bg: 'success.main',
            color: 'white',
            _hover: { bg: 'success.main', color: 'white' },
          })}
          _focus={{ boxShadow: '0' }}
          icon={
            <Box ml={hasCopied ? '-2px' : '0'}>
              <Icon
                name={hasCopied ? 'IconStatusCheck' : 'GeneralActionsDuplicate'}
                boxSize={variant === 'small' ? 5 : 6}
                color="currentcolor"
              />
            </Box>
          }
        />
      </Tooltip>
    </HStack>
  );
};

export default ClipboardTextBox;
