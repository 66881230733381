import React, { PropsWithChildren } from 'react';
import LogRocket from 'logrocket';
import ErrorPage from '../../../sales-conversation/src/components/campaigns/CampaignsErrorPage';
import * as Sentry from '@sentry/react';
import { isSocialLoginUrl } from '../features/authentication/constants/SocialAuth';

interface Props {
  hide?: boolean;
  fullHeight?: boolean;
}

const ErrorBoundary: React.FC<PropsWithChildren<Props>> = ({
  hide,
  fullHeight,
  children,
}) => {
  const handleError = (error: Error) => {
    LogRocket.captureException(error);
    if (process.env.NODE_ENV !== 'development') {
      if (
        !isSocialLoginUrl &&
        !window.location.href.includes('login') &&
        !window.location.href.includes('/dashboard')
      ) {
        window.location.href = '/dashboard?popup=error';
      }
    }
  };

  const FallbackElement = <ErrorFallback hide={hide} fullHeight={fullHeight} />;

  return (
    <Sentry.ErrorBoundary
      fallback={FallbackElement}
      beforeCapture={(scope) => {
        scope.setTag('crash', true);
      }}
      onError={handleError}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

interface Props {
  hide?: boolean;
  fullHeight?: boolean;
}

const ErrorFallback: React.FC<Props> = ({ hide, fullHeight }) => {
  if (hide) {
    return <></>;
  }

  if (isSocialLoginUrl) {
    return <ErrorPage isCampaign={true} />;
  }

  if (process.env.NODE_ENV === 'development') {
    return <div>Your page threw an error.</div>;
  }

  return <ErrorPage fullHeight={fullHeight} />;
};

export default ErrorBoundary;
