import { getHubspotChatElement } from '@common/src/hooks/support-chat/useMoveHubspotBubble';
import { useEffect } from 'react';

/**
 * A custom hook that controls the visibility of the Hubspot chat bubble.
 *
 * @param isHidden - A boolean flag that determines whether the Hubspot chat bubble should be hidden
 *
 * The hook manages the visibility of the Hubspot chat element by:
 * - Setting the element's visibility to 'hidden' when isHidden is true
 * - Setting the element's visibility to 'visible' when isHidden is false
 * - Restoring visibility to 'visible' when the component unmounts
 */
const useHideHubspotBubble = (isHidden = true) => {
  const hideHubspotElement = (hide: boolean) => {
    const hubspotElement = getHubspotChatElement();
    if (hubspotElement) {
      hubspotElement.style.visibility = hide ? 'hidden' : 'visible';
    }
  };

  useEffect(() => {
    if (isHidden) {
      hideHubspotElement(true);
    } else {
      hideHubspotElement(false);
    }
    return () => {
      hideHubspotElement(false);
    };
  }, [isHidden]);
};

export default useHideHubspotBubble;
