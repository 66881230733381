import React, { useState } from 'react';
import { Section, ProductCategory } from '../../types/yellowHiveTypes';
import { Car, Building2, Scale, Wallet, Palmtree, SearchX } from 'lucide-react';
import ProductListItem from './ProductListItem';
import ProductDetailDrawer from './ProductDetailDrawer';

export const sectionIcons = {
  Car,
  Building2,
  Scale,
  Wallet,
  Palmtree,
};

interface ProductListProps {
  sections: Section[];
  category: ProductCategory;
  searchTerm: string;
  selectedTags: string[];
}

export const ProductList: React.FC<ProductListProps> = ({
  sections,
  category,
  searchTerm,
  selectedTags,
}) => {
  const [openedProduct, setOpenedProduct] = useState<string | null>(null);

  const filteredSections = sections
    .map((section) => ({
      ...section,
      products: section.products.filter((product) => {
        const matchesCategory = product.category === category;
        const matchesSearch =
          searchTerm === '' ||
          product.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesTags =
          selectedTags.length === 0 ||
          selectedTags.some((tag) => product.tags.includes(tag));

        return matchesCategory && matchesSearch && matchesTags;
      }),
    }))
    .filter((section) => section.products.length > 0);

  if (filteredSections.length === 0) {
    return (
      <div className="empty-state">
        <SearchX size={48} className="empty-icon" />
        <h3 className="empty-title">Geen producten gevonden</h3>
        <p className="empty-description">
          {searchTerm
            ? `Geen producten komen overeen met: "${searchTerm}"`
            : selectedTags.length > 0
            ? 'Geen producten gevonden met de geselecteerde tags'
            : 'Er zijn geen producten beschikbaar in deze categorie'}
        </p>
      </div>
    );
  }

  return (
    <>
      <ProductDetailDrawer
        isOpen={!!openedProduct}
        product={filteredSections
          .flatMap((section) => section.products)
          .find((product) => product.id === openedProduct)}
        onClose={() => setOpenedProduct(null)}
      />
      <div className="product-card">
        <div className="product-columns">
          {filteredSections.map((section) => {
            const IconComponent = sectionIcons[section.iconType];
            return (
              <div key={section.id} className="product-column">
                <div className="column-header">
                  {IconComponent && <IconComponent className="column-icon" />}
                  <h2 className="column-title">{section.title}</h2>
                </div>
                <div className="product-list">
                  {section.products.map((product) => (
                    <ProductListItem
                      key={product.id}
                      product={product}
                      onClick={() => setOpenedProduct(product.id)}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
