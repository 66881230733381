import { Tag } from '../types/yellowHiveTypes';
import { particulierSections } from './particulierData';
import { bedrijfsmatigSections } from './bedrijfsmatigData';

export const tags: Tag[] = [
  { id: 'evenementen', name: 'Evenementen' },
  { id: 'koeriersdienst', name: 'Koeriersdienst' },
  { id: 'offshore', name: 'Offshore' },
  { id: 'mkb', name: 'MKB' },
  { id: 'media', name: 'Media' },
  { id: 'marine', name: 'Marine' },
  { id: 'scheepvaart', name: 'Scheepvaart' },
  { id: 'taxibedrijf', name: 'Taxibedrijf' },
  { id: 'transport', name: 'Transport' },
  { id: 'visserij', name: 'Visserij' },
  { id: 'vastgoed', name: 'Vastgoed' },
  { id: 'vve', name: 'VVE' },
  { id: 'welness', name: 'Welness' },
  { id: 'windmolens', name: 'Windmolens' },
  { id: 'zzp', name: 'ZZP' },
];

export const sections = [...particulierSections, ...bedrijfsmatigSections];
