import React from 'react';
import {
  Box,
  Button,
  EntityDrawer,
  Grid,
  HStack,
  Image,
  Spacer,
  VStack,
} from '@wegroup/design-system';
import { Product } from '../../types/yellowHiveTypes';
import { useTranslation } from 'react-i18next';
import {
  AlertTriangle,
  ClipboardCheck,
  FilePlus,
  Settings,
} from 'lucide-react';
import useHideHubspotBubble from '@common/src/hooks/support-chat/useHideHubspotBubble';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  product?: Product;
}

const ProductDetailDrawer: React.FC<Props> = ({ isOpen, product, onClose }) => {
  const { t } = useTranslation();
  useHideHubspotBubble();

  return (
    <EntityDrawer
      title={product?.name || 'Product'}
      rightElement={
        <HStack spacing="2">
          <Button size="sm" variant="grayedGhost" onClick={onClose}>
            {t('CLOSE')}
          </Button>
        </HStack>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      {product && (
        <VStack align="stretch" spacing="8">
          <Box maxW="sm" mx="auto">
            <Image src="https://yinco.nl/application/files/5416/8561/2796/1280.png" />
          </Box>
          <p>{product.description}</p>
          <Spacer />
          <Grid templateColumns="1fr 1fr" gap="2">
            <Button leftIcon={<FilePlus size={18} />} variant="white">
              Verzekering aanvragen
            </Button>
            <Button leftIcon={<ClipboardCheck size={18} />} variant="white">
              Status aanvraag
            </Button>
            <Button leftIcon={<Settings size={18} />} variant="white">
              Verzekering wijzigen
            </Button>
            <Button
              leftIcon={<AlertTriangle size={18} />}
              variant="white"
              // color="danger.main"
            >
              Schade melden
            </Button>
          </Grid>
        </VStack>
      )}
    </EntityDrawer>
  );
};

export default ProductDetailDrawer;
