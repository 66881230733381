import React from 'react';
import StreetSearch, { StreetSearchProps } from './StreetSearch';
import ManualStreet from './ManualStreet';
import { compareProps } from '../../../../../../../../tarifications/src/legacy/services/proFlowService';

type Props = StreetSearchProps & {
  isManualInput: boolean;
  name?: string;
  onBlur?: () => void;
  onFocus?: () => void;
};

const StreetPicker: React.FC<Props> = ({ isManualInput, ...props }) => {
  if (isManualInput) return <ManualStreet {...props} />;
  return <StreetSearch {...props} />;
};

export default React.memo(StreetPicker, compareProps);
