import {
  FormControl,
  FormLabel,
  CountrySelectWithFlag,
  FormErrorMessage,
} from '../../../../../../index';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { compareProps } from '../../../../../../../tarifications/src/legacy/services/proFlowService';
import { useLocale } from '../../../../../../../../i18n';

interface Props {
  name?: string;
  value?: string;
  onChange: (country?: string | null) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: FieldError;
  isPrefilled?: boolean;
  isDisabled?: boolean;
  onlyFlag?: boolean;
  /** Array of language codes that will show in the countrypicker */
  supportedCountries?: string[];
}

export const SUPPORTED_COUNTRIES = [
  'BE',
  'NL',
  'BQ',
  'CW',
  'AW',
  'SX',
] as const;

const Country: React.FC<Props> = ({
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  error,
  isPrefilled,
  isDisabled,
  onlyFlag,
  supportedCountries,
}) => {
  const { t } = useTranslation();
  const { userLang } = useLocale();

  return (
    <FormControl w="auto" isInvalid={!!error}>
      <FormLabel>{t('COUNTRY')}</FormLabel>
      <CountrySelectWithFlag
        onlyFlag={onlyFlag}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        isDisabled={isDisabled}
        isPrefilled={isPrefilled}
        lang={userLang}
        supportedCountries={supportedCountries}
      />
      {error?.message && (
        <FormErrorMessage>{t(error.message)}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default React.memo(Country, compareProps);
