import React from 'react';
import { Center, Icon, IconButton, Text, VStack } from '@wegroup/design-system';

interface Props {
  value: number;
  onChange: (zoom: number) => void;
  min?: number;
  max?: number;
}

const PdfViewerZoomControls: React.FC<Props> = ({
  value,
  onChange,
  min = 0.75,
  max = 2,
}) => {
  return (
    <VStack
      borderRadius="md"
      boxShadow="level3"
      align="stretch"
      w="10"
      spacing="0"
      position="absolute"
      top="4"
      right="6"
      zIndex="1"
    >
      <IconButton
        variant="white"
        borderBottom="0"
        aria-label="zoom-in"
        borderBottomRightRadius="none"
        onClick={() => onChange(Math.min(max, value + 0.25))}
        borderBottomLeftRadius="none"
        icon={<Icon name="GeneralActionsZoomIn" />}
        isDisabled={value >= max}
      />
      <Center bg="white" w="100%" h="10" border="1px" borderColor="gray.50">
        <Text textStyle="extraSmallBody" color="gray.500">
          {Math.round(value * 100)}%
        </Text>
      </Center>
      <IconButton
        variant="white"
        borderTop="none"
        borderTopRightRadius="none"
        borderTopLeftRadius="none"
        aria-label="zoom-out"
        onClick={() => onChange(Math.max(min, value - 0.25))}
        isDisabled={value <= min}
        icon={<Icon name="GeneralActionsZoomOut" />}
      />
    </VStack>
  );
};

export default PdfViewerZoomControls;
