import React from 'react';

import { Skeleton, SkeletonText } from '@wegroup/design-system';

const UploadSkeleton: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Skeleton
      px="4"
      py="3"
      endColor="gray.200"
      startColor="gray.50"
      opacity={0.3}
    >
      <SkeletonText h="6" />
    </Skeleton>
  );
};

export default UploadSkeleton;
