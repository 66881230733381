import { Section } from '../types/yellowHiveTypes';

export const particulierSections: Section[] = [
  {
    id: 'verkeer',
    title: 'Verkeer & Vervoer',
    iconType: 'Car',
    products: [
      {
        id: 'aanhangwagen',
        name: 'Aanhangwagen',
        category: 'Particulier',
        section: 'verkeer',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['transport'],
      },
      {
        id: 'autoverzekering',
        name: 'Autoverzekering',
        category: 'Particulier',
        section: 'verkeer',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['transport'],
      },
      {
        id: 'bestelautoverzekering',
        name: 'Bestelautoverzekering',
        category: 'Particulier',
        section: 'verkeer',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['transport', 'koeriersdienst'],
      },
      {
        id: 'camperverzekering',
        name: 'Camperverzekering',
        category: 'Particulier',
        section: 'verkeer',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'fietsverzekering',
        name: 'Fietsverzekering',
        category: 'Particulier',
        section: 'verkeer',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'motorverzekering',
        name: 'Motorverzekering',
        category: 'Particulier',
        section: 'verkeer',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'oldtimerverzekering',
        name: 'Oldtimerverzekering',
        category: 'Particulier',
        section: 'verkeer',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'pechhulp',
        name: 'Pechhulp',
        category: 'Particulier',
        section: 'verkeer',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'scooterverzekering',
        name: 'Scooterverzekering',
        category: 'Particulier',
        section: 'verkeer',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'scootmobielverzekering',
        name: 'Scootmobielverzekering',
        category: 'Particulier',
        section: 'verkeer',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
    ],
  },
  {
    id: 'wonen',
    title: 'Wonen & Gezin',
    iconType: 'Building2',
    products: [
      {
        id: 'aansprakelijkheid',
        name: 'Aansprakelijkheid',
        category: 'Particulier',
        section: 'wonen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'glasverzekering',
        name: 'Glasverzekering',
        category: 'Particulier',
        section: 'wonen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['vastgoed', 'vve'],
      },
      {
        id: 'inboedel',
        name: 'Inboedel',
        category: 'Particulier',
        section: 'wonen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'kostbaarheden',
        name: 'Kostbaarheden',
        category: 'Particulier',
        section: 'wonen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'ongevallen-wonen',
        name: 'Ongevallen verzekering',
        category: 'Particulier',
        section: 'wonen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'rechtsbijstandverzekering',
        name: 'Rechtsbijstandverzekering',
        category: 'Particulier',
        section: 'wonen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'vakantiewoning-wonen',
        name: 'Vakantiewoning',
        category: 'Particulier',
        section: 'wonen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['vastgoed'],
      },
      {
        id: 'woonhuis',
        name: 'Woonhuis',
        category: 'Particulier',
        section: 'wonen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['vastgoed', 'vve'],
      },
      {
        id: 'zorgverzekering-wonen',
        name: 'Zorgverzekering',
        category: 'Particulier',
        section: 'wonen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['welness'],
      },
    ],
  },
  {
    id: 'vrijetijd',
    title: 'Vrije tijd & Hobby',
    iconType: 'Palmtree',
    products: [
      {
        id: 'bootverzekering',
        name: 'Bootverzekering',
        category: 'Particulier',
        section: 'vrijetijd',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['scheepvaart'],
      },
      {
        id: 'bruiloftverzekering',
        name: 'Bruiloftverzekering',
        category: 'Particulier',
        section: 'vrijetijd',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['evenementen'],
      },
      {
        id: 'caravanverzekering',
        name: 'Caravanverzekering',
        category: 'Particulier',
        section: 'vrijetijd',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'droneverzekering',
        name: 'Droneverzekering',
        category: 'Particulier',
        section: 'vrijetijd',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['media'],
      },
      {
        id: 'fietsverzekering-vrijetijd',
        name: 'Fietsverzekering',
        category: 'Particulier',
        section: 'vrijetijd',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'fotoapparatuurverzekering',
        name: 'Fotoapparatuurverzekering',
        category: 'Particulier',
        section: 'vrijetijd',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['media'],
      },
      {
        id: 'golfverzekering',
        name: 'Golfverzekering',
        category: 'Particulier',
        section: 'vrijetijd',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'muziekinstrumenten',
        name: 'Muziekinstrumenten',
        category: 'Particulier',
        section: 'vrijetijd',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'paardenverzekering',
        name: 'Paardenverzekering',
        category: 'Particulier',
        section: 'vrijetijd',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'reisverzekering',
        name: 'Reisverzekering',
        category: 'Particulier',
        section: 'vrijetijd',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'vakantiewoning-vrijetijd',
        name: 'Vakantiewoning',
        category: 'Particulier',
        section: 'vrijetijd',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['vastgoed'],
      },
    ],
  },
  {
    id: 'inkomen',
    title: 'Inkomen & Financiële Zekerheid',
    iconType: 'Wallet',
    products: [
      {
        id: 'hypotheek',
        name: 'Hypotheek',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['vastgoed'],
      },
      {
        id: 'lijfrenteverzekering',
        name: 'Lijfrenteverzekering',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'nabestaandenverzekering',
        name: 'Nabestaandenverzekering',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'ongevallen-inkomen',
        name: 'Ongevallen verzekering',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'overlijdensrisicoverzekering',
        name: 'Overlijdensrisicoverzekering',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'pensioen',
        name: 'Pensioen',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'persoonlijke-lening',
        name: 'Persoonlijke lening',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'uitvaartverzekering',
        name: 'Uitvaartverzekering',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'woonlasten-verzekering',
        name: 'Woonlasten verzekering',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['vastgoed'],
      },
      {
        id: 'betalen',
        name: 'Betalen',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'beleggingsrekening',
        name: 'Beleggingsrekening',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'beleggingsverzekering',
        name: 'Beleggingsverzekering',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'sparen',
        name: 'Sparen',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: [],
      },
      {
        id: 'zorgverzekering-inkomen',
        name: 'Zorgverzekering',
        category: 'Particulier',
        section: 'inkomen',
        description:
          'Met een aanhangwagenverzekering is uw aanhanger verzekerd tegen schade en diefstal. Ideaal voor bedrijven die regelmatig transport uitvoeren.',
        logo: 'https://via.placeholder.com/150',
        tags: ['welness'],
      },
    ],
  },
];
