import React from 'react';
import { Tag } from '../../types/yellowHiveTypes';
import {
  Calendar,
  Truck,
  Anchor,
  Package,
  Camera,
  Navigation,
  Ship,
  Car,
  Box,
  Compass,
  Home,
  Users,
  Sun,
  Wind,
  Briefcase,
} from 'lucide-react';

const tagIcons: Record<string, React.ComponentType<{ className?: string }>> = {
  evenementen: Calendar,
  koeriersdienst: Truck,
  offshore: Anchor,
  mkb: Package,
  media: Camera,
  marine: Navigation,
  scheepvaart: Ship,
  taxibedrijf: Car,
  transport: Box,
  visserij: Compass,
  vastgoed: Home,
  vve: Users,
  welness: Sun,
  windmolens: Wind,
  zzp: Briefcase,
};

interface TagFilterProps {
  tags: Tag[];
  selectedTags: string[];
  onTagToggle: (tagId: string) => void;
}

export const TagFilter: React.FC<TagFilterProps> = ({
  tags,
  selectedTags,
  onTagToggle,
}) => {
  return (
    <div className="tag-filter">
      {tags.map((tag) => {
        const IconComponent = tagIcons[tag.id.toLowerCase()];
        const isSelected = selectedTags.includes(tag.id);
        return (
          <button
            key={tag.id}
            onClick={() => onTagToggle(tag.id)}
            className="tag-button"
            aria-pressed={isSelected}
          >
            {IconComponent && <IconComponent className="tag-icon" />}
            {tag.name}
          </button>
        );
      })}
    </div>
  );
};
