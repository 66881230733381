/* eslint-disable no-use-before-define */
import { request } from './httpSessionService';
import { REST_HOST, AUTH_HOST } from '../../../../../env.config';
import { getI18nCountryCode, getI18nLanguageCode } from '../../../../../i18n';
import { stringify } from 'query-string';
import { isSocialLoginUrl } from '../../../../app/src/features/authentication/constants/SocialAuth';

/* * * * * * * * * * * * * * *
 * AUTHENTICATION
 * * * * * * * * * * * * * * */
export async function changePassword(oldPassword, password) {
  const path = 'v1/api/aut/v1/api/user/me/password';
  const [resp, status] = await request({
    path,
    method: 'PATCH',
    payload: {
      old_password: oldPassword,
      password: password,
    },
  });
  return [resp, status];
}

export const getAnonProspectById = (_prospectId) =>
  request({
    path: `v1/api/prospects/${_prospectId}`,
    method: 'GET',
    origin: REST_HOST,
  });

/* * * * * * * * * * * * * * *
 * EXTERNAL AUTHENTICATION
 * * * * * * * * * * * * * * */

export async function fbAuth(accessToken, userId, askRole, askBrokerId) {
  const path = 'v1/api/aut/v1/api/auth';
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: {
      facebook_access_token: accessToken,
      facebook_user_id: userId,
      ask_role: askRole,
      ask_broker_id: askBrokerId,
    },
  });
  return [resp, status];
}

export async function googleAuth(googleCode, askRole, askBrokerId) {
  const path = 'v1/api/aut/v1/api/auth';
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: {
      google_code: googleCode,
      ask_role: askRole,
      ask_broker_id: askBrokerId,
    },
  });
  return [resp, status];
}

/**
 * @param {object} param
 * @property {string} askDistributionId
 */
export async function captchaAuth({
  token,
  email,
  firstName,
  lastName,
  askBrokerId,
  askDistributionId = null,
}) {
  const path = 'auth/captcha';
  const payload = {
    token,
    version: 'V2_INVISIBLE',
    email,
    first_name: firstName,
    last_name: lastName,
    ask_broker_id: askBrokerId,
    ask_distribution_id: askDistributionId,
  };
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload,
    origin: AUTH_HOST,
  });
  if (resp.access_token)
    sessionStorage.setItem('access-token-social', resp.access_token);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * BROKER DATA
 * * * * * * * * * * * * * * */

export async function retrieveUserData() {
  const path = `v1/api/users/me`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function patchUserMetaData(payload) {
  const path = `v1/api/users/me`;
  const [resp, status] = await request({
    path,
    method: 'PATCH',
    origin: REST_HOST,
    payload,
  });
  return [resp, status];
}

export async function getAllBrokersForUser() {
  if (isSocialLoginUrl) return [];
  const path = `v1/api/me/brokers`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function retrieveBrokerById() {
  if (isSocialLoginUrl) return [];
  const path = `v1/api/brokers/me`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function retrieveBrokerByUserId(userId) {
  const path = `v1/api/brokers/${userId}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function retrieveHMACForUser(params) {
  const path = `v1/api/users/me/hmac?platform=${params.platform}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function patchBrokerById(brokerId, payload, distributionId) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/brokers/${brokerId}`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

export async function patchBrokerSettingsById(brokerId, payload) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/settings`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

export async function updateUserById(userId, payload) {
  const path = `v1/api/aut/v1/api/user`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

export async function retrieveDistributionById(distributionId) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function patchDistributionById(distributionId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

// These are the insurance companies (allianz, axa, ...)
export async function retrieveAffiliationsById(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/affiliations`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

// This is almost CALLANT only (honda, valckenier, ...)
export async function retrieveAffinitiesById(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/affinities`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveMembershipsById(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/memberships`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveAvailableAffiliations() {
  const path = `v1/api/broker/v1/api/affiliations`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveIntegrationsById(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/integrations`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveBrokerSettingsById(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/settings`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function validateBrokerAffiliation(name, payload) {
  let path = `v1/api/quotes/v1/api/check/${name}?`;
  for (let i = 0; i < Object.keys(payload).length; i++) {
    if (i === 0) {
      path += `${Object.keys(payload)[i]}=${payload[Object.keys(payload)[i]]}`;
    } else {
      path += `&${Object.keys(payload)[i]}=${payload[Object.keys(payload)[i]]}`;
    }
  }
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function patchBrokerAffiliation(brokerId, name, payload) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/affiliations/${name}`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

export async function patchBrokerIntegrations(brokerId, payload) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/integrations`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

export async function addBrokerAffiliation(brokerId, payload) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/affiliations`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function deleteBrokerAffiliation(brokerId, name) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/affiliations/${name}`;
  const [resp, status] = await request({ path, method: 'DELETE' });
  return [resp, status];
}

export async function uploadBrokerAvatar(brokerId, formData) {
  const path = `v1/api/broker/v2/api/brokers/${brokerId}/upload/assistant_avatar`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: formData,
  });
  return [resp, status];
}

export async function uploadAvatarAsUser(userId, payload) {
  const path = `v1/api/broker/v1/api/users/${userId}/upload/avatar`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function retrieveCanSellList(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/can_sell`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getThemeByName(themeName) {
  const path = `v1/api/broker/v1/api/themes/${themeName}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * DISTRIBUTION
 * * * * * * * * * * * * * * */

export async function uploadDistributionLogo(distributionId, formData) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/upload/logo`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: formData,
  });
  return [resp, status];
}

export async function uploadDistributionConversationLogo(
  distributionId,
  formData,
) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/upload/logo_fe`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: formData,
  });
  return [resp, status];
}

export async function retrieveBrokersByDistribution(distributionId) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/brokers`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function CreateSignedDistUploadId(distributionId, filename) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/uploads`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: filename,
  });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * MAILER
 * * * * * * * * * * * * * * */

export async function sendFeedbackWithMailer(formData) {
  const path = `v1/api/mailer/v1/api/mail/feedback`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: formData,
  });
  return [resp, status];
}

export async function sendRequestAccessMail(formData) {
  const path = `v1/api/mailer/v1/api/mail/broker/request_access`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: formData,
  });
  return [resp, status];
}

export async function createAffiliationAccessReq(affiliation, formData) {
  const path = `v1/api/affiliations/${affiliation}/access-requests`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: formData,
  });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * POLICY
 * * * * * * * * * * * * * * */

export async function getSignatureCallbackCheck(signature_id) {
  const path = `v1/api/policy/v1/api/signature_check/${signature_id}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function exportToInsurerById(id) {
  const path = `v1/api/policy/v1/api/save`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: { quotes: [id] },
  });
  return [resp, status];
}

export async function exportToInsurerById_V2(id) {
  const path = `v1/api/quotes/v1/api/quotes/${id}`;
  const [resp, status] = await request({ path, method: 'POST', payload: {} });
  return [resp, status];
}

export async function exportToCrmById(id, payload) {
  const path = `v1/api/quotes/v1/api/quotes/${id}/crm`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * FLOWS
 * * * * * * * * * * * * * * */

/* * * * * * * * * * * * * * *
 * QUOTES
 * * * * * * * * * * * * * * */

const proFlowDepricationText =
  'Using depricated route, please use proFlowQuestionsPrefillRequest';

/**
 * @deprecated since version 1.10.5
 */
export async function retrieveProFlowQuestions(query, payload) {
  console.warn(proFlowDepricationText);
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/quotes/v1/api/multiquotes/questions${queryPath}&country_code=${getI18nCountryCode()}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

/**
 * @deprecated since version 1.10.5
 */
export async function retrieveProFlowQuestionsProspect(query, payload) {
  console.warn(proFlowDepricationText);
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/quotes/v1/api/multiquotes${queryPath}&country_code=${getI18nCountryCode()}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

/**
 * @deprecated since version 1.10.5
 */
export async function getPyModelsObjectsFromQuoteStorage(quoteId) {
  const path = `v1/api/quotes/v1/api/quotes/${quoteId}?typecast=PYMODELS`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * @deprecated since version 1.10.5
 */
export async function getPyModelsObjectsFromQuoteStorageSMT(quoteId) {
  const path = `v1/api/quotes/v1/api/quotes/${quoteId}?typecast=SMT`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * @deprecated since version 1.10.5
 */
export async function createQuotesSession() {
  const path = `v1/api/quotes/v1/api/quotes/session`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * @deprecated since version 1.10.5
 */
export async function retrieveAvailableOptionsByQuote(
  insuranceType,
  insuranceCompany,
  insurance,
) {
  const path = `v1/api/quotes/v1/api/quote/${insuranceType}/${insuranceCompany}/${insurance}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * @deprecated since version 1.31.5
 */
export async function retrieveSmtObjectFromSession(sessionId) {
  const path = `v1/api/quotes/v1/api/quotes/session/${sessionId}?typecast=SMT`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveMarketInsuranceCompanies(country = 'BE') {
  const path = `v1/api/marketplace/v1/api/retrieve_insurance_companies/${country}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}
export async function retrieveInsuranceCompanies(country = 'BE') {
  const path = `v1/api/quotes/v1/api/multiquotes/support?country_code=${country}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function proFlowQuestionsPrefillRequest(
  query,
  payload,
  country = 'BE',
) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/quotes/v1/api/multiquotes${queryPath}&country_code=${country}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}
/**
 * Retrieve current session answers by session id
 * @param {string} sessionId - Session id for retrieving answers
 */
export async function getSessionObjectById(sessionId) {
  const path = `v1/api/quotes/v1/api/multiquotes/sessions/${sessionId}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * Retrieve available options for quote
 * @param {string} query - ex: 'insurance_type=legal&&company=vivium'
 */
export async function retrieveProFlowGuarantees(query, country = 'BE') {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/quotes/v1/api/multiquotes/tags${queryPath}&country_code=${country}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function createRiskObjectSession(sessionId, payload) {
  const path = `v1/api/quotes/v1/api/multiquotes/${sessionId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function batchQuotesForSession(sessionId, payload) {
  const path = `v1/api/quotes/v1/api/batch/${sessionId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function getResultsFromFinishedBatchById(batchId, query) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/quotes/v1/api/batch/results/${batchId}${queryPath}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * Calculate a home quote by id and quote specification
 * @param {Object} obj - An object.
 * @param {string} obj.insuranceType - ex: 'car', 'home',...
 * @param {string} obj.insuranceCompany - ex: 'pnp', 'vivium',...
 * @param {string} obj.insurance - ex: 'civil_liability', 'mini_omnium',...
 * @param {Object} obj.payload - ex: {quote_specifications: {...}, id: 'adb6231f-44c1-45fa-a334-19b7655e4037'}
 * @param {string} obj.query - ex: 'option=omnium&&option=legal'
 */
export async function calculateQuoteByInsuranceType({
  insuranceType,
  insuranceCompany,
  insurance,
  payload,
  query,
}) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/quotes/v1/api/quote/${insuranceType}/${insuranceCompany}/${insurance}${queryPath}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function getFlowById(_flowId, _offer, _origin) {
  const path = `v1/api/quotes/v1/api/flows/${_flowId}?offer_id=${_offer.offerId}&revision=${_offer.offerRevision}`;
  return await request({ path, method: 'GET', origin: _origin });
}

export async function sendFlowById(_flowId, _offer, _answers, _origin) {
  const path = `v1/api/flows/${_flowId}/answers`;
  return await request({
    path,
    method: 'POST',
    origin: _origin,
    payload: _answers,
  });
}

export async function createContractForFlowById(
  _distributionId,
  _payload,
  _origin,
  _contractFlow,
) {
  let endpoint = '';

  if (_contractFlow === 'pia') {
    if (getI18nCountryCode() === 'NL') {
      endpoint = 'pia-car-nl';
    } else endpoint = 'pia-car-be';
  }

  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contract-conversions/${endpoint}`;

  return await request({
    path,
    method: 'POST',
    payload: _payload,
    origin: _origin,
  });
}

export const createContractForBaloise = async (_payload) => {
  const path = 'v1/api/quotes/v1/api/companies/baloise/be/products/car/offers';
  return await request({ path, method: 'POST', payload: _payload });
};

/* * * * * * * * * * * * * * *
 * ADDRESS
 * * * * * * * * * * * * * * */

// Default country code will be taken from broker data in future, hardcoded here for now
export const DEFAULT_COUNTRY_CODE = 'BE';

// Hardcoded for now, will have to fetch from backend once new countries get supported faster
export const SUPPORTED_COUNTRIES_CITIES_STREETS = ['BE'];
export const SUPPORTED_COUNTRIES_ADDRESS_INFO = ['BE', 'CY'];

/**
 * @deprecated since 1.18.2, use searchAddressREST instead
 */
export async function searchAddress(
  query,
  language = 'NL',
  country = DEFAULT_COUNTRY_CODE,
) {
  const path = `v1/api/address/v1/api/search?q=${query}&language_code=${language}&country_code=${country}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function searchAddressREST(
  query,
  language = 'NL',
  country = DEFAULT_COUNTRY_CODE,
) {
  const path = `v1/api/address/search?q=${query}&lang=${language}&country_code=${
    country || 'BE'
  }`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

/**
 * @deprecated since 1.18.2, use retrieveAddressCitiesREST instead
 */
export async function retrieveAddressCities({
  zipcode,
  searchQuery,
  countryCode = DEFAULT_COUNTRY_CODE,
}) {
  let path;
  if (zipcode && !searchQuery) {
    path = `v1/api/address/v1/api/cities?zipcode=${zipcode}`;
  } else if (searchQuery && !zipcode) {
    path = `v1/api/address/v1/api/cities?q=${searchQuery}`;
  }

  if (countryCode) path += `&country_code=${countryCode}`;

  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveAddressCitiesREST({
  searchQuery,
  zipcode = null,
  countryCode = DEFAULT_COUNTRY_CODE,
  lang = null,
}) {
  let path = 'v1/api/address/cities';

  if (zipcode && !searchQuery) {
    path += `?zipcode=${zipcode}`;
  } else if (searchQuery && !zipcode) {
    path += `?q=${searchQuery}`;
  }

  if (countryCode) path += `&country_code=${countryCode}`;
  if (lang) {
    path += `&lang=${lang}`;
  } else {
    path += `&lang=${getI18nLanguageCode()}`;
  }

  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

/**
 * @deprecated since 1.18.2, use retrieveAddressStreetsREST instead
 */
export async function retrieveAddressStreets({
  searchQuery,
  city,
  countryCode = DEFAULT_COUNTRY_CODE,
}) {
  let path;
  if (searchQuery) {
    path = `v1/api/address/v1/api/streets?q=${searchQuery}&q_city=${city}`;
  } else if (city) {
    path = `v1/api/address/v1/api/streets?q_city=${city}`;
  }

  if (countryCode) path += `&country_code=${countryCode}`;

  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveAddressStreetsREST({
  searchQuery,
  city,
  zipcode = null,
  countryCode = DEFAULT_COUNTRY_CODE,
  lang = null,
}) {
  let path = 'v1/api/address/streets';
  if (searchQuery) {
    path += `?q=${searchQuery}&q_city=${city}`;
  } else if (city) {
    path += `?q_city=${city}`;
  }

  if (zipcode) path += `&zipcode=${zipcode}`;
  if (countryCode) path += `&country_code=${countryCode}`;
  if (lang) {
    path += `&lang=${lang}`;
  } else {
    path += `&lang=${getI18nLanguageCode()}`;
  }

  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function retrieveRandomAddress() {
  const path = `v1/api/address/v1/api/random`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

// Retrieve address info

export async function retrieveAddressInfo(AddressData, imgSizes) {
  const path = `v1/api/address/v2/api/info${
    (imgSizes && `?width=${imgSizes.width}&height=${imgSizes.height}`) || ''
  }`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: AddressData,
  });
  // load marked image
  const imgURL = resp?.links?.find(
    (lnk) => lnk.rel === 'aerial_images_marked_satellite',
  )?.href;
  const [img, imgStatus] = await getImage(imgURL);
  if (imgStatus <= 201) resp.markedImage = img;
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CAR
 * * * * * * * * * * * * * * */

export function retrieveLicensePlate() {
  // const path = `v1/api/car/v1/api/license_plate/${licensePlate}`;
  // const [resp, status] = await request({path, method: 'GET' });
  return [{ code: 400, detail: 'License lookup not supported' }, 400];
}

export async function retrieveCarData(userId, payload) {
  const path = `v1/api/marketplace/v1/api/info/car/${userId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function postCarData(userId, payload) {
  const path = `v1/api/marketplace/v1/api/section/car/${userId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function postCarExtraData(userId, payload) {
  const path = `v1/api/marketplace/v1/api/section/car_extra/${userId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function retrieveCarBrands() {
  const path = `v1/api/marketplace/v1/api/info/car_manual/brands`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveCarModels(brand) {
  const path = `v1/api/marketplace/v1/api/info/car_manual/models?brand=${brand}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveCarVersions(brand, model) {
  const path = `v1/api/marketplace/v1/api/info/car_manual/versions?model=${model}&brand=${brand}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CRM
 * * * * * * * * * * * * * * */

/* Leads
-------------------------------------------------- */
export async function getAllLeadsByDistributionId(
  _distributionId,
  _pageNumber,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads?pagelen=10&page=${_pageNumber}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getFilteredLeadsByDistributionId(
  _distributionId,
  _pageNumber,
  _filteredOptions,
  _topSearchValue,
) {
  let query = '';
  if (_topSearchValue) query += `&q=${encodeURIComponent(_topSearchValue)}`;
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads?pagelen=15&page=${_pageNumber}${query}${
    _filteredOptions?.length > 0 ? `&` : ''
  }${_filteredOptions}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getLeadById(_distributionId, _leadId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * Route to calculate an offer from a lead.
 * @param {string} _distributionId The distribution ID.
 * @param {string} _leadId The ID of the lead you want to calculate an offer for.
 * @param {object} _payload The payload as defined in the portal.
 */
export async function calculateQuoteAsLead(_distributionId, _leadId, _payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}/calculate/quote`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: _payload,
  });
  return [resp, status];
}

/**
 * Convert a current lead into an offer.
 * @param {string} _distributionId The distribution ID.
 * @param {string} _leadId The ID of the lead you want to convert to an offer.
 * @param {object} _payload The payload as defined in portal.
 */
export async function convertLeadToOffer(_distributionId, _leadId, _payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/leads/${_leadId}`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: _payload,
  });
  return [resp, status];
}

/**
 * Transfer Lead By Id
 * @param {string} leadId The ID of the current lead
 * @param {string} target The distribution ID where you want to tranfer to
 */
export async function transferLeadById(leadId, target) {
  const path = `v1/api/leads/${leadId}/transfers`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: { distribution_id: target },
    origin: REST_HOST,
  });
  return [resp, status];
}

/* Offers
-------------------------------------------------- */
export async function getAllOffersByDistributionId(
  _distributionId,
  _pageNumber,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers?pagelen=20&page=${_pageNumber}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getFilteredOffersByDistributionId(
  _distributionId,
  _pageNumber,
  _queryString,
  _topSearchValue,
) {
  let query = '';
  if (_topSearchValue) query += `&q=${encodeURIComponent(_topSearchValue)}`;
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers?pagelen=15&page=${_pageNumber}${query}${
    _queryString?.length > 0 ? `&` : ''
  }${_queryString}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getOfferById(_distributionId, _offerId, _revisionId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${
    _revisionId ? _revisionId : 0
  }`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getOfferRevisions(_distributionId, _offerId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function changeOfferStatus(
  _distributionId,
  _offerId,
  _revisionId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revisionId}`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

export async function updateOfferById(_distributionId, _offerId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

export async function updateContractById(
  _distributionId,
  _contract_case_id,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/cases/${_contract_case_id}`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

export async function updateLeadById(_distributionId, _leadId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

export async function convertOfferToContract(
  _distributionId,
  _offerId,
  _revisionId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/offers/${_offerId}/revisions/${_revisionId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

/**
 * Fetch data of a specific document connected to an offer.
 * @param {string} _distributionId The distribution ID.
 * @param {string} _offerId The offer ID.
 * @param {int} _revision The revision of the offer
 * @param {string} _documentId The ID of the document to get.
 */
export async function getOfferRevisionDocumentById(
  _distributionId,
  _offerId,
  _revision,
  _documentId,
) {
  // const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/documents`;
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revision}/documents/${_documentId}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * @deprecated since version 1.12.4
 */
export async function exportOfferToInsuranceModule(
  _distributionId,
  _offerId,
  _revision,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revision}/export/insurance_module`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    origin: REST_HOST,
  });
  return [resp, status];
}

/**
 * @deprecated since version 1.12.4
 */
export async function exportOfferToCrm(_distributionId, _offerId, _revision) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revision}/export/crm`;
  const [resp, status] = await request({ path, method: 'POST' });
  return [resp, status];
}

/* Contracts
-------------------------------------------------- */
/**
 * Function to convert an offer to a contract while overwriting the guarantees.
 * @param {string} _distributionId The ID of the distribution.
 * @param {string} _offerId The ID of the offer to convert.
 * @param {integer} _revisionId The revision number of the offer to convert.
 * @param {object} payload The payload to post.
 */
export async function convertOfferToContractManual(
  _distributionId,
  _offerId,
  _revisionId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/offers/${_offerId}/revisions/${_revisionId}/manual`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

// Contracts
export async function getAllContractsByDistributionId(
  _distributionId,
  _pageNumber,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts?pagelen=20&page=${_pageNumber}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getFilteredContractsByDistributionId(
  _distributionId,
  _pageNumber,
  _queryString,
  _topSearchValue,
) {
  let query = '';
  if (_topSearchValue) query += `&q=${encodeURIComponent(_topSearchValue)}`;
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts?pagelen=15&page=${_pageNumber}${query}${
    _queryString?.length > 0 ? `&` : ''
  }${_queryString}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getContractById(_distributionId, _contractId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getContractRevisions(_distributionId, _contractCaseId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/cases/${_contractCaseId}/contracts`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function generatePolicyDocument(_distributionId, _contractId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/regenerate_document`;
  const [resp, status] = await request({ path, method: 'POST' });
  return [resp, status];
}

/**
 * Create contract addendum.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {string} _contractId The 'ID' of the contract.
 * @param {object} payload The payload which will be added.
 */
export async function createContractAddendum(
  _distributionId,
  _contractId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/addendums`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

/**
 * Edit contract addendum.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {string} _contractCaseId The 'ID' of the contract case.
 * @param {string} _contractId The 'ID' of the contract.
 * @param {object} payload The payload which will be added.
 */
export async function editContractAddendum(
  _distributionId,
  _contractCaseId,
  _contractId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/cases/${_contractCaseId}/contracts/${_contractId}`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

// Prospects
export async function getAllProspectsByDistributionId(
  _distributionId,
  _pageNumber,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects?pagelen=10${
    _pageNumber ? `&page=${_pageNumber}` : ''
  }`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getFilteredProspectsByDistributionId(
  _distributionId,
  _pageNumber,
  _filteredOptions,
  _topSearchValue,
  pagelen = 15,
) {
  let query = '';
  if (_topSearchValue) query += `&q=${encodeURIComponent(_topSearchValue)}`;
  const path = `v1/api/distributions/${_distributionId}/parties?pagelen=${pagelen}&page=${_pageNumber}${query}${
    _filteredOptions?.length > 0 ? `&` : ''
  }${_filteredOptions}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * Get Prospect by ID.
 * @deprecated Use `getPartyById` instead
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {string} _prospectId The ID of the prospect.
 */
export async function getProspectById(_distributionId, _prospectId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_prospectId}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export const getPartyById = async (partyId) => {
  const path = `v1/api/parties/${partyId}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
};

/**
 * Get Risk Analysis By Prospect ID.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {string} _prospectId The ID of the prospect.
 */
export async function getRiskAnalysisByProspectId(
  _distributionId,
  _prospectId,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_prospectId}/risk_analysis`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * Create Prospect.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {('companies' | 'customers')} _type The type of the prospect.
 * @param {object} payload The payload which will be added.
 */
export async function createProspect(_distributionId, _type, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_type}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

/**
 * Delete Prospect by ID.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {string} _prospectId The ID of the prospect.
 */
export async function deleteProspectById(_distributionId, _prospectId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_prospectId}`;
  const [resp, status] = await request({ path, method: 'DELETE' });
  return [resp, status];
}

/**
 * Update Prospect of type Customer by ID.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {string} _prospectId The ID of the customer / lead / prospect.
 * @param {object} payload The payload which will be patched.
 */
export async function updateProspectCustomerById(
  _distributionId,
  _prospectId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/customers/${_prospectId}`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

/**
 * Update Prospect of type Company by ID.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {string} _prospectId The ID of the customer / lead / prospect.
 * @param {object} payload The payload which will be patched.
 * @param {boolean} explicitNull Should be able to pass nullable values?
 */
export async function updateProspectCompanyById(
  _distributionId,
  _prospectId,
  payload,
  explicitNull = false,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/companies/${_prospectId}`;
  const [resp, status] = await request({
    path,
    method: 'PATCH',
    payload,
    explicitNull,
  });
  return [resp, status];
}

/**
 * Create Lead.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {('companies' | 'customers')} _type The type of the prospect.
 * @param {object} payload The payload which will be added.
 */
export async function createLead(_distributionId, _type, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_type}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

/**
 * Update Lead.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {('companies' | 'customers')} _type The type of the prospect.
 * @param {object} payload The payload which will be added.
 */
export async function updateLead(_distributionId, _leadId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

export async function getLeadsByProspectId(_distributionId, _prospectId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads?pagelen=100&prospect_id=${_prospectId}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getOffersByProspectId(_distributionId, _prospectId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers?pagelen=100&prospect_id=${_prospectId}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getContractsByProspectId(_distributionId, _prospectId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts?pagelen=100&prospect_id=${_prospectId}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getBrokersByDistributionId(_distributionId, query) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/brokers?pagelen=1000${
    query ? `&q=${query}` : ''
  }`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

// Notes
export async function getNotesByLeadId(_distributionId, _leadId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}/notes`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function appendNoteToLead(_distributionId, _leadId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}/notes`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function deleteLeadNoteById(_distributionId, _leadId, _noteId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}/notes/${_noteId}`;
  const [resp, status] = await request({ path, method: 'DELETE' });
  return [resp, status];
}

export async function getNotesByContractsId(_distributionId, _contractId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/notes`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function appendNoteToContracts(
  _distributionId,
  _contractId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/notes`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function deleteContractsNoteById(
  _distributionId,
  _contractId,
  _noteId,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/notes/${_noteId}`;
  const [resp, status] = await request({ path, method: 'DELETE' });
  return [resp, status];
}

export async function getNotesByOfferId(_distributionId, _offerId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/notes`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function appendNoteToOffer(_distributionId, _offerId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/notes`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function deleteOfferNoteById(_distributionId, _offerId, _noteId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/notes/${_noteId}`;
  const [resp, status] = await request({ path, method: 'DELETE' });
  return [resp, status];
}

export const getLeadProviderById = async (id) => {
  const path = `v1/api/lead-providers/${id}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
};

// RiskObjects

/**
 * Get Risk Object by call type and id.
 * @param {string} _distributionId The ID of the distribution.
 * @param {string} _callTypeId The id of the call type type.
 * @param {('cars' | 'residences' | 'party_groups' | 'families' | 'two_wheelers' | 'boats' | 'bicycles' |'semi_trailers' | 'trailers' | 'legal' | 'miscellanea')} _riskType The type of the risk object.
 * @param {('prospects' | 'offers' | 'leads')} _tallType The type the page.
 */
export async function getRiskObjectsByCallType(
  _distributionId,
  _callTypeId,
  _riskType,
  _callType,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/${_callType}/${_callTypeId}/risk_objects/${_riskType}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * Get Risk Object by risk type and id.
 * @param {string} _distributionId The ID of the distribution.
 * @param {('teachers' | 'cars' | 'residences' | 'two_wheelers' | 'families' | 'party_groups' )} _riskType The type of the risk object.
 * @param {string} _riskObjectId The id of the risk object.
 * @param {string} _revision The rivision id of the risk object.
 */
export async function getRiskObjectByTypeAndId(
  _distributionId,
  _riskType,
  _riskObjectId,
  _revision,
) {
  const path = `v1/api/risk_objects/${_riskType}/${_revision}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

/**
 * @deprecated - Use `createRiskObjectRevisionREST` instead
 */
export async function createRiskObjectRevision(
  _distributionId,
  _prospectId,
  _riskType,
  _riskObjectId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_prospectId}/risk_objects/${_riskType}/${_riskObjectId}`;
  const [resp, status] = await request({ path, method: 'PUT', payload });
  return [resp, status];
}

export async function createRiskObjectRevisionREST(
  _prospectId,
  _riskType,
  _riskObjectId,
  payload,
) {
  const path = `v1/api/parties/${_prospectId}/${_riskType}/${_riskObjectId}/revisions`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    origin: REST_HOST,
    payload,
  });
  return [resp, status];
}

export async function createRiskObjectForProspect(
  _distributionId,
  _prospectId,
  _riskType,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_prospectId}/risk_objects/${_riskType}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function getAllAffinities() {
  const path = `v1/api/broker/v1/api/affinities`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

// Insurances and guarantees
export async function getAllInsuranceCompanies() {
  const path = `v1/api/broker/v1/api/insurance_companies?pagelen=100`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getInsuranceCompanyByKey(insuranceCompanyKey) {
  const path = `v1/api/broker/v1/api/insurance_companies/${insuranceCompanyKey}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getInsurances(insuranceCompanyKey, country = 'BE') {
  const path = `v1/api/broker/v1/api/insurance_companies/${insuranceCompanyKey}/insurances?country_code=${country}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getGuarantees(insuranceCompanyKey, insuranceId) {
  const path = `v1/api/broker/v1/api/insurance_companies/${insuranceCompanyKey}/insurances/${insuranceId}/guarantees/info?pagelen=100`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

// CRMs
export async function retrieveAllCRMs() {
  const path = `v1/api/broker/v1/api/broker_crms?pagelen=100`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function exportLeadToCRM(_distributionId, _leadId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}/export/crm`;
  const [resp, status] = await request({ path, method: 'POST' });
  return [resp, status];
}

export async function exportOfferToCRM(_distributionId, _offerId, _revision) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revision}/export/crm`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function mailOfferToProspect(
  _distributionId,
  _offerId,
  _revision,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revision}/mails`;
  const [resp, status] = await request({ path, method: 'POST' });
  return [resp, status];
}

export async function exportContractToCRM(_distributionId, _contractId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/export/crm`;
  const [resp, status] = await request({ path, method: 'POST' });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * DOCGEN
 * * * * * * * * * * * * * * */

export async function generatePolicyProposition(payload) {
  const path = `v1/api/docgen/v1/api/proposition`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function generateTerminationLetter(payload) {
  const path = `v1/api/docgen/v1/api/notice`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function generateInformationRequirementSheet(payload) {
  const path = `v1/api/docgen/v1/api/information_requirement_sheet`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function generateCustomerSheet(payload) {
  const path = `v1/api/docgen/v1/api/customer_sheet`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function generateCallantProposition(affinity, payload) {
  const path = `v1/api/docgen/v1/api/callant/${affinity}/proposition`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function generateTerminationLetterV2(payload) {
  const path = `v1/api/docgen/v2/api/notice`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function generateInformationRequirementSheetV2(payload) {
  const path = `v1/api/docgen/v2/api/information_requirement_sheet`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function generateCustomerSheetV2(payload) {
  const path = `v1/api/docgen/v2/api/customer_sheet`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function getDocumentById(id) {
  const path = `v1/api/docgen/v1/api/documents/${id}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function createProspectDocuments({
  distributionId,
  prospectId,
  documents,
}) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/prospects/${prospectId}/documents`;
  const payload = { documents };
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function getDocumentSignatureUrl({
  distributionId,
  prospectId,
  documentId,
}) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/prospects/${prospectId}/documents/${documentId}/signatures`;
  const [resp, status] = await request({ path, method: 'POST' });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * ENUMS
 * * * * * * * * * * * * * * */

/**
 * Get ENUMS by name
 * @param {string} _enumName Name of the enum you want to fetch.
 */
export async function getEnumByName(_enumName) {
  const path = `v1/api/broker/enums/${_enumName}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * Get ENUMS by name from the REST API
 * @param {string} _enumName Name of the enum you want to fetch.
 */
export async function getEnumByNameREST(_enumName) {
  const path = `enums/${_enumName}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * QUOTES AND OFFERS
 * * * * * * * * * * * * * * */

/**
 * Calculate a new offer.
 * @param {string} _distributionId The ID of the distribution.
 * @param {string} _offerId The ID of the offer you wish to recalculate.
 * @param {object} _payload The payload to be put.
 */

export async function calculateQuote(
  _distributionId,
  _offerId,
  _payload,
  _revisionId,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revisionId}/calculate/quote`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: _payload,
  });
  return [resp, status];
}

export async function calculateQuoteByContractId(
  _distributionId,
  _contractId,
  _payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/calculate/quote`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: _payload,
  });
  return [resp, status];
}

/**
 * Calculate a new contract.
 * @param {string} _distributionId The ID of the distribution.
 * @param {string} _contractId The ID of the contract you wish to recalculate.
 * @param {object} _payload The payload to be put.
 */

export async function calculateContractQuote(
  _distributionId,
  _contractId,
  _payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/calculate/quote`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: _payload,
  });
  return [resp, status];
}

/**
 * Create an offer revision.
 * @param {string} _distributionId The ID of the distribution.
 * @param {string} _offerId The ID of the offer you wish to make a revision of.
 * @param {object} _payload The payload to be put.
 */
export async function createOfferRevision(_distributionId, _offerId, _payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}`;
  const [resp, status] = await request({
    path,
    method: 'PUT',
    payload: _payload,
  });
  return [resp, status];
}

export async function createContractRevision(
  _distributionId,
  _contractCaseId,
  _contractsId,
  _payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/cases/${_contractCaseId}/contracts/${_contractsId}`;
  const [resp, status] = await request({
    path,
    method: 'PUT',
    payload: _payload,
  });
  return [resp, status];
}

export async function createContractRevisionManual(
  _distributionId,
  _contractCaseId,
  _contractsId,
  _payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/cases/${_contractCaseId}/contracts/${_contractsId}/manual`;
  const [resp, status] = await request({
    path,
    method: 'PUT',
    payload: _payload,
  });
  return [resp, status];
}

export async function getAffinityByCompanyKey(_companyKey) {
  const path = `v1/api/broker/v1/api/affinities/${_companyKey}`;
  const [resp, status] = await request({ path, method: 'GET' });

  return [resp, status];
}

export async function getAffinityInsurancesByCompanyKey(
  companyKey,
  affinityKey,
) {
  const path = `v1/api/broker/v1/api/affinities/${companyKey}/${affinityKey}/insurances`;
  const [resp, status] = await request({ path, method: 'GET' });

  return [resp, status];
}

export async function getAffinityInsurancesByCompanyKeyAndId(
  companyKey,
  affinityKey,
  id,
) {
  const path = `v1/api/broker/v1/api/affinities/${companyKey}/${affinityKey}/insurances/${id}/guarantees/info`;
  const [resp, status] = await request({ path, method: 'GET' });

  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CUSTOMER PAGE
 * * * * * * * * * * * * * * */

/**
 * Fetch an offer from a customer page.
 * @param {string} _distributionId The ID of the distribution.
 * @param {string} _offerId The ID of the offer.
 * @param {number} _revision The revision number.
 */
export async function getOfferAsCustomerById(_offerId, _revision, _token) {
  const path = `v1/api/broker/v1/api/customer/offers/${_offerId}/revisions/${_revision}`;
  const customHeaders = { 'x-wg-customer-email-token': _token };

  const [resp, status] = await request({ path, method: 'GET', customHeaders });
  return [resp, status];
}

/**
 * Calculate a new quote from a customer page.
 * @param {string} _offerId The ID of the offer.
 * @param {object} _payload The payload to be sent
 * @param {string} _token Validation token the customer receives in the URL.
 */
export async function calculateQuoteAsCustomer(
  _offerId,
  _revisionId,
  _payload,
  _token,
) {
  const path = `v1/api/broker/v1/api/customer/offers/${_offerId}/calculate/quote?revision=${_revisionId}`;
  const customHeaders = { 'x-wg-customer-email-token': _token };

  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: _payload,
    customHeaders,
  });
  return [resp, status];
}

/**
 * Create a new offer revision from a customer page.
 * @param {string} _offerId The ID of the offer.
 * @param {object} _payload The payload to be sent
 * @param {string} _token Validation token the customer receives through the URL.
 */
export async function createOfferRevisionAsCustomer(
  _offerId,
  _payload,
  _token,
) {
  const path = `v1/api/broker/v1/api/customer/offers/${_offerId}`;
  const header = { 'x-wg-customer-email-token': _token };

  const [resp, status] = await request({
    path,
    method: 'PUT',
    payload: _payload,
    customHeaders: header,
  });
  return [resp, status];
}

export async function createCarRevisionAsCustomer(
  _riskObjectId,
  _payload,
  _token,
) {
  const path = `v1/api/broker/v1/api/customer/risk_objects/cars/${_riskObjectId}`;
  const header = { 'x-wg-customer-email-token': _token };

  const [resp, status] = await request({
    path,
    method: 'PUT',
    payload: _payload,
    customHeaders: header,
  });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * AUTOMOBILE
 * * * * * * * * * * * * * * */

/**
 * @deprecated since version 1.23.5
 */

export async function retrieveCarByFebiacId(_febiacId) {
  const path = `v1/api/vehicle/v1/api/automobile/febiac/${_febiacId}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveCarByFebiacIdREST(_febiacId) {
  const path = `v1/api/vehicles/automobile/id/premium?febiac_id=${_febiacId}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

/**
 * @deprecated since version 1.17.14
 */

export async function retrieveAutomobileBrands(query) {
  const queryPath = query ? `?pagelen=500&${query}` : '?pagelen=500';
  const path = `v1/api/vehicle/v1/api/automobile/brands${queryPath}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}
export async function retrieveAutomobileBrandsREST(query) {
  const queryPath = query ? `?pagelen=500&${query}` : '?pagelen=500';
  const path = `v1/api/vehicles/automobile/brands${queryPath}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}
/**
 * @deprecated since version 1.17.14
 */
export async function retrieveAutomobileModelsByBrand(brand, query) {
  const queryPath = query ? `?pagelen=500&${query}` : '?pagelen=500';
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brand}/series${queryPath}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}
export async function retrieveAutomobileModelsByBrandREST(brand, query) {
  const queryPath = query ? `?pagelen=500&${query}` : '?pagelen=500';
  const path = `v1/api/vehicles/automobile/brands/${brand}/series${queryPath}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}
/**
 * @deprecated since version 1.17.14
 */
export async function retrieveAutomobileVersionsBySeries(
  brand,
  seriesId,
  query,
) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brand}/series/${seriesId}/versions${queryPath}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveAutomobileVersionsBySeriesREST(seriesId, query) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/vehicles/automobile/series/${seriesId}/versions${queryPath}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

/**
 * @deprecated since version 1.17.14
 */
export async function retrieveAutomobileByCar(brand, seriesId, carId) {
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brand}/series/${seriesId}/versions/${carId}`;
  const customHeaders = {
    'Accept-Language': getI18nLanguageCode().toUpperCase(),
  };
  const [resp, status] = await request({ path, method: 'GET', customHeaders });
  return [resp, status];
}
export async function retrieveAutomobileByCarIdREST(
  versionId,
  plan = 'freemium',
) {
  const path = `v1/api/vehicles/automobile/versions/${versionId}/${plan}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function retrieveLicensePlateV2(licensePlate) {
  const path = `v1/api/vehicle/v1/api/license_plate/${licensePlate}?country_code=BE&vehicle_type=CAR`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveVehicleByVin(vin) {
  const path = `v1/api/vehicle/v1/api/vin/${vin}`;
  const customHeaders = {
    'Accept-Language': getI18nLanguageCode().toUpperCase(),
  };
  const [resp, status] = await request({ path, method: 'GET', customHeaders });
  return [resp, status];
}
export async function retrieveVehiclesByVin(vin) {
  const path = `v1/api/vehicles/search?lang=${getI18nLanguageCode().toUpperCase()}`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: { vin_number: vin },
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function retrieveVehiclesByNumberPlate(
  numberPlate,
  country = getI18nCountryCode().toUpperCase(),
) {
  const path = `v1/api/vehicles/search?lang=${getI18nLanguageCode().toLowerCase()}`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: { number_plate: numberPlate, country_code: country.toUpperCase() },
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function retrieveAutoMobileVersionsKwFactsBySeries(
  brand,
  seriesId,
  query,
) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brand}/series/${seriesId}/versions/facts/kw${queryPath}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}
export async function retrieveAutoMobileVersionsKwFactsBySeriesREST(
  brand,
  seriesId,
  query,
) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/vehicles/automobile/series/${seriesId}/facts${queryPath}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function retrieveAutoMobilePremiumByCarId(id) {
  const path = `v1/api/vehicles/automobile/versions/${id}/premium`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * ADDRESS
 * * * * * * * * * * * * * * */

export async function getInfoAboutAddress(_payload) {
  const path = `v1/api/address/v2/api/info`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: _payload,
  });
  return [resp, status];
}

export async function getRoomsEstimationFromAddress(_id) {
  const path = `v1/api/address/v2/api/room_estimation/${_id}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function getPropertiesFromAddress(_id) {
  const path = `v1/api/address/v2/api/properties/${_id}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CREATE OFFER
 * * * * * * * * * * * * * * */

export async function createOffer(_distributionId, _prospectId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_prospectId}/offers`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CAMPAIGNS
 * * * * * * * * * * * * * * */

/**
 * Retrieve campaign data by campaing id.
 * @param {string} campaingId The ID of the campaign.
 */
export async function retrieveCampaignById(campaignId) {
  const path = `v1/api/campaigns/${campaignId}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * Retrieve campaign data by campaing id.
 * @param {string} distributionId The ID of the distribution.
 * @param {string} type campaign type "EMAIL", "WEBSITE".
 */
export async function retrieveCampaignsByDistribution(
  _distributionId,
  _type,
  _query,
  _pagelen,
  _filters,
) {
  let params;
  if (_type) {
    params = `?type=${_type}&pagelen=${_pagelen || 100}${
      _query ? `&q=${encodeURIComponent(_query)}` : ''
    }`;
  } else {
    params = `${
      _query
        ? `?q=${encodeURIComponent(_query)}${
            _pagelen && `&pagelen=${_pagelen}`
          }`
        : `${_pagelen ? `?pagelen=${_pagelen}` : ''}`
    }`;
  }

  if (_filters) params += _filters;

  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/campaigns${params}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

/**
 * Retrieve campaign data by campaing id.
 * @param {string} distributionId The ID of the distribution.
 */
export async function createCampaign(distributionId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/campaigns`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

/**
 * Retrieve campaign data by campaing id.
 * @param {string} distributionId The ID of the distribution.
 * @param {string} campaingId The ID of the campaign.
 * @param {string} type campaign type "EMAIL", "WEBSITE".
 */
export async function patchCampaign(distributionId, campaignId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/campaigns/${campaignId}`;
  const [resp, status] = await request({ path, method: 'PATCH', payload });
  return [resp, status];
}

/**
 * Retrieve party data by party id.
 * @param {string} id The ID of the distribution.
 */
export async function retrievePartyById(id) {
  const path = `v1/api/parties/${id}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * MARKETPLACE
 * * * * * * * * * * * * * * */

export async function postPersonalData(userId, payload) {
  const path = `v1/api/marketplace/v1/api/section/personal/${userId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function postAddressInfo(userId, payload) {
  const path = `v1/api/marketplace/v1/api/info/address/${userId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function postAddressData(userId, payload) {
  const path = `v1/api/marketplace/v1/api/section/home/${userId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function postAddressExtraData(userId, payload) {
  const path = `v1/api/marketplace/v1/api/section/home_extra/${userId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function postExtras(userId, payload) {
  const path = `v1/api/marketplace/v1/api/section/extra/${userId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function postFamilyInfo(userId, payload) {
  const path = `v1/api/marketplace/v1/api/section/family/${userId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

export async function postLegalInfo(userId, payload) {
  const path = `v1/api/marketplace/v1/api/section/legal/${userId}`;
  const [resp, status] = await request({ path, method: 'POST', payload });
  return [resp, status];
}

/**
 * Save all the data of the user for an lead campaign flow
 * @param {string} lang the current language
 * @param {string} id the louise flow id
 */
export async function postLeadFlow(lang, id) {
  const path = `v1/api/marketplace/v1/api/lead/${lang}/${id}`;
  const [resp, status] = await request({ path, method: 'POST', payload: {} });
  return [resp, status];
}

export async function validateInputCall(method, url, body) {
  const path = url.replace(/^\//, '');
  const [resp, status] = await request({ path, method, payload: body });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * SETTINGS
 * * * * * * * * * * * * * * */

export const sendMembershipInvites = async (
  invitees = [],
  invitee_admins = [],
  inherit_config_from_inviter_for = [],
) => {
  const path = `v1/api/distributions/invitations`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: { invitees, invitee_admins, inherit_config_from_inviter_for },
    origin: REST_HOST,
  });
  return [resp, status];
};

export async function checkIfCbeIsValid(_cbe) {
  const path = `v1/api/broker/v1/api/validate/cbe`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: { cbe: _cbe },
  });
  return [resp, status];
}

/* * * * * * * * * * * ff * * * *
 * INSURANCES
 * * * * * * * * * * * * * * */

/**
 * Retrieve acknowledgements
 */
export async function retrieveAcknowledgements(_insuranceType, _lang) {
  const path = `v1/api/broker/v1/api/insurances/acknowledgements?insurance_type=${_insuranceType}&language=${_lang}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
}

export async function retrieveInsuranceCompaniesREST() {
  const path = `v1/api/insurance_companies`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * IMAGE
 * * * * * * * * * * * * * * */

/** Gets image via the specified URI, this request adds auth headers */
export async function getImage(_uri) {
  const [resp, status] = await request({
    path: _uri,
    method: 'GET',
    isBlob: true,
  });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * FILES
 * * * * * * * * * * * * * * */

/**
 * Gets the errorBanner from files.wegroup.be
 * @param {*} env The current environment: 'staging' or 'production'
 */
export async function getErrorBanner(env) {
  const req = await fetch(`https://files.wegroup.be/error_banner/${env}.json`);

  const resp = await req.json();

  return [resp, req.status];
}

/**
 * Gets the errorBanner from files.wegroup.be
 * @param {*} env The current environment: 'staging' or 'production'
 */
export async function getDisabledCompanies(env) {
  const req = await fetch(
    `https://files.wegroup.be/disabled_companies/${env}.json`,
  );

  const resp = await req.json();

  return [resp, req.status];
}

/* * * * * * * * * * * * * * *
 * CLAIMS
 * * * * * * * * * * * * * * */

export async function createClaimsAsSocailLogin(distributionId, payload) {
  const path = `v1/api/distributions/${distributionId}/claims`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload,
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function getClaimByTokenAsCustomer(token) {
  const path = `v1/api/claims/customer/token/${token}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function getClaimEventsAsCustomer(token) {
  const path = `v1/api/claims/customer/token/${token}/events`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function createDocumentAsCustomer(token, payload) {
  const path = `v1/api/claims/customer/token/${token}/documents`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: { documents: payload },
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function updateDocumentMetaDataAsCustomer(
  token,
  documentId,
  payload,
) {
  const path = `v1/api/claims/customer/token/${token}/documents/${documentId}`;
  const [resp, status] = await request({
    path,
    method: 'PATCH',
    payload,
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function updateDocumentMetaData(claimId, documentId, payload) {
  const path = `v1/api/claims/${claimId}/documents/${documentId}`;
  const [resp, status] = await request({
    path,
    method: 'PATCH',
    payload,
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function retrieveDistributionByIdClaims(distributionId) {
  const path = `v1/api/distributions/${distributionId}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function uploadClaimDocument(category, payload) {
  const path = `v1/api/claims/upload/${category}`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload,
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function patchClaimDocument(claimId, documentId, payload) {
  const path = `v1/api/claims/${claimId}/documents/${documentId}`;
  const [resp, status] = await request({
    path,
    method: 'PATCH',
    payload,
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function uploadCustomerClaimDocument(category, payload) {
  const path = `v1/api/claims/customer/upload/${category}`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload,
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function getClaimDocumentEnums() {
  const path = `/enums/CLAIM_DOCUMENT_CATEGORY`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function sendClaimToKeypoint(claimId) {
  const path = `v1/api/claims/${claimId}/connections/keypoint/declaration`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function generateTokenForSocialLoginClaims(
  policyHolderId,
  claimId,
) {
  const path = `v1/api/claims/generate/token`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: {
      claim_id: claimId,
      policy_holder_id: policyHolderId,
    },
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function downloadClaimDetailReport(
  claimId,
  lang,
  includeAttachments = false,
) {
  const path = `v1/api/claims/${claimId}/report?lang=${lang}&include_attachments=${includeAttachments}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function createClaimEntity(claimId, payload) {
  const path = `v1/api/claims/${claimId}/entities`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload,
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function addNoteAsCustomer(eventId, token, payload) {
  const path = `v1/api/claims/customer/token/${token}/events/${eventId}/notes`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload,
    origin: REST_HOST,
  });
  return [resp, status];
}

export async function sendClaimReportByMail(claimId, emails) {
  const path = `v1/api/claims/${claimId}/report/email`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: {
      mail_to: emails,
      include_attachments: true,
    },
    origin: REST_HOST,
  });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CONNECTIONS
 * * * * * * * * * * * * * * */

export async function getBrokerConnectionsById(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/connections`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });

  return [resp, status];
}

export async function deleterBrokerConnectionById(brokerId, key) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/connections/${key}`;
  const [resp, status] = await request({
    path,
    method: 'DELETE',
    origin: REST_HOST,
  });

  return [resp, status];
}

export async function createBrokerConnection(brokerId, integrationName, code) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/connections/${integrationName}`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: { code, host: window.location.host },
    customHeaders: { redirect: 'follow' },
    origin: REST_HOST,
  });

  return [resp, status];
}

export async function createBrokerCloudConnection(distributionId, code) {
  const path = `v1/api/distributions/${distributionId}/connections/brokercloud`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: { code, host: window.location.host },
    customHeaders: { redirect: 'follow' },
    origin: REST_HOST,
  });

  return [resp, status];
}

export async function getDistributionConnectionsById(distributionId) {
  const path = `v1/api/distributions/${distributionId}/connections`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });

  return [resp, status];
}

export async function deleteDistributionConnectionById(distributionId, key) {
  const path = `v1/api/distributions/${distributionId}/connections/${key}`;
  const [resp, status] = await request({
    path,
    method: 'DELETE',
    origin: REST_HOST,
  });

  return [resp, status];
}

export async function updateDistributionConnectionById(
  distributionId,
  key,
  payload,
) {
  const path = `v1/api/distributions/${distributionId}/connections/${key}`;
  const [resp, status] = await request({
    path,
    method: 'PATCH',
    payload,
    origin: REST_HOST,
  });

  return [resp, status];
}

export async function createDistributionConnection(
  distributionId,
  key,
  payload,
) {
  const path = `v1/api/distributions/${distributionId}/connections/${key}`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload,
    origin: REST_HOST,
  });

  return [resp, status];
}
/* * * * * * * * * * * * * * *
 * TOOLKIT
 * * * * * * * * * * * * * * */

export async function getToolkitQuota() {
  const path = `quotas`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });

  return [resp, status];
}

/**
 * @param {string} code
 * @param {array} users
 */
export async function upgradePlanById(
  distId,
  plan,
  paymentInterval,
  code = null,
  users = null,
) {
  const path = `v1/api/broker/v1/api/distributions/${distId}/upgrades`;
  const [resp, status] = await request({
    path,
    method: 'PUT',
    payload: {
      plan,
      payment_interval: paymentInterval,
      code,
      ...(users && { additional_accounts: users }),
    },
  });
  return [resp, status];
}

export async function getToolkitAddress(
  { street, country_code, zipcode, housenr, boxnr },
  plan,
) {
  const qs = new URLSearchParams({
    street,
    country_code,
    zipcode,
    housenr,
    boxnr,
  });
  const path = `v1/api/address/info/${plan}?${qs}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });

  return [resp, status];
}

export async function getPromotionsById(distributionId) {
  const path = `v1/api/distributions/${distributionId}/promotions`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });

  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * KBO
 * * * * * * * * * * * * * * */

export async function getKboByName(kbo) {
  const path = `https://www.btw-opzoeken.be/VATSearch/Search?KeyWord=${kbo}`;
  const response = await fetch(path);
  const resp = await response.json();

  return [resp, response.status];
}

export async function getEnterprisesByName(name_or_number, country) {
  const qString = stringify({
    q: name_or_number,
    country_code: country,
  });

  const path = `v1/api/enterprises/search?${qString}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
  });
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * EXTERNAL
 * * * * * * * * * * * * * * */

// MapBox API call to get address from coordinates
export async function getAddress(longitude, latitude) {
  const MAPBOX_TOKEN =
    'pk.eyJ1Ijoid2Vncm91cCIsImEiOiJja2g0bmFqNXAwMzRmMnJuMTZubGd3NnBvIn0.RW31nsm8Hddr89pWODZJVQ';
  const { data, status } = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude}%2C${latitude}.json?access_token=${MAPBOX_TOKEN}&cachebuster=1611092877575&autocomplete=true&types=address&types=postcode&types=place&types=country`,
  );
  if (status === 200 && data?.features[0]) {
    return {
      addressObject: {
        street: data.features?.[0]?.text,
        housenr: data.features?.[0]?.address,
        zipcode: data.features?.[1]?.text,
        city: data.features?.[2]?.text,
        country_code: data.features?.[3]?.properties?.short_code?.toUpperCase(),
      },
      address: data.features?.[0].place_name,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
    };
  }
}
