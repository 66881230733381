/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import i18n, {
  getI18nLanguageCode,
  handleResponseLanguage,
} from '../../../../../i18n';
import { capitalize } from 'lodash';
import { QuoteStatus } from '../../../../../types/Quotes';
import { stringify } from 'query-string';
import { calculateQuoteByInsuranceType } from '../../../../common/src/legacy/services/apiRouterService';
import { request } from '../../../../common/src/legacy/services/httpSessionService';
import { isEqual } from 'lodash';
import { uniqBy } from 'lodash';

export const compareProps = (prevProps, nextProps) =>
  isEqual(prevProps, nextProps);

/* * * * * * * * * * * * * * *
 * QUOTES
 * * * * * * * * * * * * * * */

/** Used for mapping omnium and mini_omnium, otherwise returns key */
export const mapOptionName = (key) => {
  if (!key || typeof key !== 'string') return '';
  if (key?.match(new RegExp('mini_omnium.*'))) {
    return i18n.t('mini_omnium');
  } else if (key?.match(new RegExp('omnium.*'))) {
    return i18n.t('omnium');
  }
  return key;
};

export const showFranchise = (type, value) => {
  switch (type) {
    case 'fixed':
      return `€${value} ${i18n.t('Checkout.insured.franchiseTypes.fixed')}`;
    case 'variable':
      return `€${value} ${i18n.t('Checkout.insured.franchiseTypes.variable')}`;
    case 'percentage_on_compensation':
      return `${value * 100}% ${i18n.t(
        'Checkout.insured.franchiseTypes.percentage_on_compensation',
      )}`;
    case 'percentage_on_car_value':
      return `${value * 100}% ${i18n.t(
        'Checkout.insured.franchiseTypes.percentage_on_car_value',
      )} `;
    case 'english':
      return `€${value} ${i18n.t('Checkout.insured.franchiseTypes.english')}`;
    case 'identity':
      return `€${value} ${i18n.t('Checkout.insured.franchiseTypes.identity')}`;
    default:
      return ' - ';
  }
};

export const transformFranchisesToOptions = (franchises) => {
  return (
    franchises?.map(({ title, type, value }) => ({
      label: handleResponseLanguage(title),
      value: { type, value },
    })) || []
  );
};

export const transformStringArrayToOptions = (stringArray) => {
  return stringArray.map((item) => {
    return { value: item, label: item };
  });
};

export const getOptionObject = (options, selectedOptionValue) => {
  if (typeof selectedOptionValue === 'object') return selectedOptionValue;
  if (!options)
    return {
      value: selectedOptionValue,
      label: selectedOptionValue,
    };

  return (
    options.find(({ value }) => value === selectedOptionValue) || {
      value: '',
      label: '',
    }
  );
};

export const sanitizeQuote = (
  quote,
  _infoInsurances,
  _distCRMName,
  answers,
) => {
  const specsAnswers = answers['SPECS'];
  const insuranceDistribution = _infoInsurances?.[quote?.insurance_company];
  const { names: insuranceCompanyNames, insurances } =
    insuranceDistribution || {};

  let sanitizedQuote = {};

  const type =
    quote?.insurance_type === 'home' ? 'residence' : quote?.insurance_type;

  const { guarantees } = insurances?.[type] || {};
  const { links } = insurances?.[type] || {};
  if (!guarantees) return;

  const namedGuarantees = Object.entries(guarantees).map(
    ([key, { names, descriptions }]) => ({
      key,
      description:
        handleResponseLanguage(descriptions) || `No description found`,
      name: handleResponseLanguage(names) || `No Name found`,
    }),
  );

  sanitizedQuote = {
    id: quote?.id,
    insurance: {
      key: quote?.insurance,
      name:
        quote?.insurance_company?.toLowerCase() === 'dela'
          ? i18n.t('Funeral care plan')
          : namedGuarantees?.find(({ key }) => key === quote?.insurance)?.name,
      description: namedGuarantees?.find(({ key }) => key === quote?.insurance)
        ?.description,
    },
    insuranceCompany: {
      key: quote?.insurance_company,
      name:
        handleResponseLanguage(insuranceCompanyNames) ||
        quote?.insurance_company,
      logo: insuranceDistribution?.logo,
      website: insuranceDistribution?.website,
    },
    insuranceType: {
      key: quote?.insurance_type,
    },
    guarantees: namedGuarantees,
    links: links,
    options: quote?.options.map((option) => ({
      key: option,
      name: namedGuarantees?.find(({ key }) => key === option)?.name,
      description: namedGuarantees?.find(({ key }) => key === option)
        ?.description,
    })),
    originalQuote: quote,
  };

  if (_distCRMName && _distCRMName?.toUpperCase() !== 'NONE') {
    sanitizedQuote = {
      ...sanitizedQuote,
      CRM: {
        key: _distCRMName,
        name: _distCRMName,
      },
    };
  }

  if (quote?.resp?.quote) {
    const { language, premium, promotions, quote: quoteDetail } = quote?.resp;
    sanitizedQuote = {
      ...sanitizedQuote,
      language,
      premium,
      promotions,
      quote: {
        base: (() => {
          const { name: key, ...rest } = quoteDetail?.base;
          return {
            key,
            name: namedGuarantees?.find(({ key: _key }) => key === _key)?.name,
            description: namedGuarantees?.find(({ key: _key }) => key === _key)
              ?.description,
            ...rest,
          };
        })(),
        details: quoteDetail?.details,
        options: quoteDetail?.options?.map((option) => {
          const { name: key, ...rest } = option;
          return {
            key,
            name: namedGuarantees?.find(({ key: _key }) => key === _key)?.name,
            description: namedGuarantees?.find(({ key: _key }) => key === _key)
              ?.description,
            ...rest,
          };
        }),
      },
    };
  } else {
    const { code, detail, domain, msg } = quote?.resp;
    sanitizedQuote = {
      ...sanitizedQuote,
      error: {
        code,
        detail: handleResponseLanguage(detail) || detail?.en,
        domain,
        msg,
      },
    };
  }

  return {
    ...sanitizedQuote,
    requests: [],
    isOpen: false,
    status: QuoteStatus.SUCCESS,
    chosenOptions: sanitizedQuote?.quote?.options
      .map(({ key }) => key)
      .filter((key) => sanitizedQuote?.insurance?.key !== key),
    chosenPromotions: sanitizedQuote?.promotions
      ?.filter(({ applied }) => applied)
      .map(({ id }) => id),
    specs: {
      save_quote: true,
      currency: 'EUR',
      bonus_malus: sanitizedQuote?.premium?.bonus_malus || 0,
      evaluation_car: sanitizedQuote?.quote?.details?.evaluation_type,
      franchise: sanitizedQuote?.quote?.details?.franchise,
      evaluation_building:
        sanitizedQuote?.quote?.details?.insured_value?.evaluation_type,
      insured_value: sanitizedQuote?.quote?.details?.insured_value?.value,
      insured_content_value:
        sanitizedQuote?.quote?.details?.insured_content_value?.value,
      ck: sanitizedQuote?.quote?.details?.ck || 0,
      flat_commission_discount:
        sanitizedQuote?.quote?.details?.flat_commission_discount || 0,
      vk_percentage: sanitizedQuote?.quote?.details?.max_vk
        ? sanitizedQuote?.quote?.details?.vk /
          sanitizedQuote?.quote?.details?.max_vk
        : 0,
      payment_interval: sanitizedQuote?.quote?.details?.payment_interval,
      modifications: sanitizedQuote?.quote?.options
        .filter(({ formula }) => !!formula)
        .map(({ key, formula }) => ({ name: key, formula: formula })),
      defaultValues: {
        originalInsuredContentValue:
          Math.round(
            sanitizedQuote?.quote?.details?.insured_content_value?.value * 100,
          ) / 100,
        originalInsuredValue:
          Math.round(
            sanitizedQuote?.quote?.details?.insured_value?.value * 100,
          ) / 100,
      },
      ...(type === 'funeral' && {
        benefit_1: specsAnswers?.find(({ id }) => id === 'specs.benefit_1')
          ?.answer,
        payment_until_1: specsAnswers?.find(
          ({ id }) => id === 'specs.payment_until_1',
        )?.answer,
        ...(specsAnswers?.find(({ id }) => id === 'funeral.add_person') && {
          benefit_2: specsAnswers?.find(({ id }) => id === 'specs.benefit_2')
            ?.answer,
          payment_until_2: specsAnswers?.find(
            ({ id }) => id === 'specs.payment_until_2',
          )?.answer,
        }),
      }),
    },
  };
};

export const sanitizeCalculatedQuote = (_quote, _infoInsurances) => {
  const { base, details, options } = _quote?.quote || {};
  const insuranceDistribution =
    _infoInsurances?.[_quote?.insurance?.company] || {};
  const { insurances } = insuranceDistribution || {};

  const { guarantees } =
    insurances?.[
      _quote?.insurance?.type === 'home' ? 'residence' : _quote?.insurance?.type
    ] || {};

  const namedGuarantees = Object.entries(guarantees || []).map(
    ([key, { names, descriptions }]) => ({
      key,
      name: handleResponseLanguage(names) || `No Name found`,
      description:
        handleResponseLanguage(descriptions) || `No description found`,
    }),
  );

  return {
    base: (() => {
      const { name: key, ...rest } = base;
      return {
        key,
        name: namedGuarantees?.filter(({ key: _key }) => key === _key)[0]?.name,
        description: namedGuarantees?.find(({ key: _key }) => key === _key)
          ?.description,
        ...rest,
      };
    })(),
    details: details,
    options: options?.map((option) => {
      const { name: key, ...rest } = option;
      return {
        key,
        name: namedGuarantees?.filter(({ key: _key }) => key === _key)[0]?.name,
        description: namedGuarantees?.find(({ key: _key }) => key === _key)
          ?.description,
        ...rest,
      };
    }),
  };
};

export const calcQuote = (quote, sessionId) => {
  const { key: insuranceType } = quote.insuranceType;
  const { key: insuranceCompany } = quote.insuranceCompany;
  const { key: insurance } = quote.insurance;

  // Clear out undefined props
  const quoteSpecs = Object.assign(
    {},
    ...Object.keys(quote.specs)
      .filter((key) => quote.specs[key] != null && quote.specs[key] !== '')
      .map((key) => ({ [key]: quote.specs[key] })),
  );

  if (
    quoteSpecs.insured_content_value ===
    quoteSpecs.defaultValues.originalInsuredContentValue
  ) {
    delete quoteSpecs.insured_content_value;
  }

  const payload = {
    insuranceType,
    insuranceCompany,
    insurance,
    payload: {
      id: sessionId,
      quote_specifications: quoteSpecs,
    },
    query: stringify({
      option: quote.chosenOptions,
      promotion: quote.chosenPromotions,
    }),
  };

  return calculateQuoteByInsuranceType(payload);
};

export const showCriteria = (required = [], _answers = [], questions = []) => {
  // show input if nothing is required
  if (required.length === 0) return true;
  const nulledRequires = required.filter(({ answer }) => answer === null);
  const generalRequires = required.filter(({ answer }) => answer !== null);

  const shouldShowQuestion =
    areAllNulledQuestionsAnswered(nulledRequires, _answers, questions) ||
    isOneOfGeneralQuestionsAnswered(generalRequires, _answers);

  return shouldShowQuestion;
};

export const areAllNulledQuestionsAnswered = (
  required = [],
  // eslint-disable-next-line no-unused-vars
  _answers = [],
  questions = [],
) => {
  // if there are no nulled questions return true
  if (required.length === 0) return false;

  // return true if all nulled questions are not answered
  return required
    .map(({ id }) => !!questions.find(({ id: _id }) => id === _id))
    .includes(false);
};

export const isOneOfGeneralQuestionsAnswered = (
  required = [],
  _answers = [],
) => {
  // if there are no questions return true
  if (required.length === 0) return false;
  // return true if id and answer matches
  return required.some((require) => {
    if (require.subfield) {
      if (require.subfield === '') {
        return console.error(
          'Empty subfield was given, something is wrong in retrieveQuestions API call content',
        );
      }

      // Split by . for nested objects
      const fields = require.subfield.split('.');
      let answerValue = _answers.find(({ id }) => id === require.id)?.answer;
      if (!answerValue) {
        // Not answered yet
        return false;
      }

      // Navigate down nested object till 1 field is left
      while (fields.length > 1) {
        const objProperty = fields.shift();
        answerValue = answerValue[objProperty];
      }
      // Should be at correct object position
      // The property will be located at index 0 of fields array
      if (
        typeof answerValue[fields[0]] === 'string' &&
        typeof require.answer === 'string'
      ) {
        return (
          answerValue[fields[0]]?.toLowerCase() ===
          require.answer?.toLowerCase()
        );
      }
      return answerValue[fields[0]] === require.answer;
    }
    return _answers.some(
      ({ id, answer }) => require.id === id && answer === require.answer,
    );
  });
};

/* * * * * * * * * * * * * * *
 * SMT creation/update
 * * * * * * * * * * * * * * */

export const getSMTRouteNaming = (_riskObject, singular) => {
  switch (_riskObject) {
    case 'car':
      return singular ? 'car' : 'cars';
    case 'residence':
    case 'home':
      return singular ? 'residence' : 'residences';
    case 'family':
      return singular ? 'family' : 'families';
    case 'legal':
      return singular ? 'legal' : 'legal';
    case 'party_group':
    case 'funeral':
      return singular ? 'party_group' : 'party_groups';
    case 'boat':
      return singular ? 'boat' : 'boats';
    case 'two_wheeler':
      return singular ? 'two_wheeler' : 'two_wheelers';
    case 'bicycle':
      return singular ? 'bicycle' : 'bicycles';
    case 'semi_trailer':
      return singular ? 'semi_trailer' : 'semi_trailers';
    case 'trailer':
      return singular ? 'trailer' : 'trailers';
    case 'miscellaneous':
      return singular ? 'miscellanea' : 'miscellaneous';
    default:
      throw new Error(`Unsupported risk object: ${_riskObject}`);
  }
};

/* * * * * * * * * * * * * * *
 * GUARANTEES
 * * * * * * * * * * * * * * */

export const getOneOfKey = (key) => {
  return `oneOf${capitalize(key)}`;
};

export const transformGuaranteesResult = (respArg, chosenGuarantees = {}) => {
  const resp = respArg;

  /*
    Transforms tags to an easier format for FE
    -> If required for another guarantee => dependantOn
      -> Contains name
    -> For each insurance type adds a oneOf
      -> Contains an error message + oneOf boolean
      -> OneOf boolean is used if a oneOf was chosen
    */

  // Set up dependantOn
  Object.keys(resp.tags).forEach((insuranceType) => {
    resp.tags[insuranceType].forEach((guarantee) => {
      guarantee.required?.forEach((requiredTag) => {
        const guaranteeIndex = resp.tags[insuranceType].findIndex(
          (guarantee) => guarantee.tag === requiredTag,
        );

        if (guaranteeIndex >= 0) {
          // Create array if non existant
          if (!resp.tags[insuranceType][guaranteeIndex].dependantOn) {
            resp.tags[insuranceType][guaranteeIndex].dependantOn = [];
            resp.tags[insuranceType][guaranteeIndex].dependantOn.push(
              guarantee.tag,
            );
          } else {
            resp.tags[insuranceType][guaranteeIndex].dependantOn.push(
              guarantee.tag,
            );
          }
        } else {
          console.error('Guarantee could not be found, which is an API error.');
        }
      });
    });
  });

  // Set up oneOf
  Object.keys(resp.tags).forEach((insuranceType) => {
    const oneOfGuarantees = resp.tags[insuranceType].filter(
      (guarantee) => guarantee.one_of,
    );

    // Check if there is a oneOf for insurancType
    if (oneOfGuarantees.length !== 0) {
      let oneOfGuaranteesString = '';

      oneOfGuarantees.forEach((guarantee, index) => {
        if (index === oneOfGuarantees.length - 1) {
          oneOfGuaranteesString += `${handleResponseLanguage(guarantee.title)}`;
        } else if (index === oneOfGuarantees.length - 2) {
          // Second last should not have comma
          oneOfGuaranteesString += `${handleResponseLanguage(
            guarantee.title,
          )} ${i18n.t('Or')} `;
        } else {
          // Add title and comma
          oneOfGuaranteesString += `${handleResponseLanguage(
            guarantee.title,
          )}, `;
        }
      });

      // OneOf is based on the chosenGuarantees
      // If chosenInsurances is changed this function gets called
      // Therefore it could be that guarantees were already chosen beforehand
      resp[getOneOfKey(insuranceType)] = {
        oneOf:
          chosenGuarantees[insuranceType]?.length > 0
            ? chosenGuarantees[insuranceType]?.some((chosenGuarantee) =>
                oneOfGuarantees
                  ?.map((oneOfGuarantee) => oneOfGuarantee.tag)
                  ?.includes(chosenGuarantee),
              )
            : false,
        message: `${i18n.t(
          'At least one of the following guarantees should be chosen',
        )}: ${oneOfGuaranteesString}`,
        multiLangMessage: {
          nl: `Er moet ten minste een van de volgende waarborgen worden gekozen: ${oneOfGuaranteesString}`,
          en: `At least one of the following guarantees should be chosen: ${oneOfGuaranteesString}`,
          fr: `Au moins une des garanties suivantes doit être choisie: ${oneOfGuaranteesString}`,
        },
      };
    }
  });
  return resp;
};

/* * * * * * * * * * * * * * *
 * REST API
 * * * * * * * * * * * * * * */

/**
 * Enum for registration types
 */
export const registrationTypeEnum = {
  YEAR: 'YEAR',
  EXACT: 'EXACT',
};

/**
 * This function populates the vehicle data by calling the provided SMT route ('self' property)
 * @param {Object} obj An object
 * @param {Object} obj.vehicle Vehicle from REST API lookup
 * @param {string} obj.countryCode Country code to search for, **required** for license plate lookup
 * @param {Function} obj.saveChangesCatalogueValue Optional function
 * @param {Function} obj.saveChangesCatalogueValueInclVat Optional function
 * @returns
 */
export const populateRestVehicle = async ({
  vehicle,
  countryCode,
  saveChangesCatalogueValue,
  saveChangesCatalogueValueInclVat,
}) => {
  let path = vehicle.self; // Contains premium route
  if (countryCode) path += `&country_code=${countryCode}`;

  const [resp, status] = await request({ path, method: 'GET' });
  let newVehicle;

  if (status === 200) {
    newVehicle = {
      // Main data
      ...resp.domain_car.car,

      // Registration data
      registration_first_type: resp.data.registration.first_type,
      registration_last_type: resp.data.registration.last_type,

      // Other data
      images: resp.data.images,
      color_hex: resp.data.physical_properties.color_hex,
      brand_logo: resp.data.general.brand_logo,
    };
  }

  if (!newVehicle) newVehicle = vehicle.car; // Fallback to less-populated vehicle.car
  if (!newVehicle.brand) newVehicle.brand = newVehicle.series; // Necessary if self route failed
  if (!newVehicle.model) newVehicle.model = newVehicle.series; // Necessary if self route failed

  if (newVehicle?.value && newVehicle?.vat_rate)
    newVehicle.catalogue_value_incl_vat = Math.round(
      newVehicle.value * (1 + newVehicle.vat_rate),
    );

  saveChangesCatalogueValue &&
    saveChangesCatalogueValue(newVehicle?.value || newVehicle?.price || 0);
  saveChangesCatalogueValueInclVat &&
    saveChangesCatalogueValueInclVat(newVehicle?.catalogue_value_incl_vat || 0);

  return newVehicle;
};

export const getLinkForAragOffer = (insurance) => {
  const baseUrl =
    'https://files.wegroup.be/documents/insurances/legal/arag/terms/';

  switch (insurance) {
    case 'traffic':
      return `${baseUrl}special_conditions_traffic_${getI18nLanguageCode()}.pdf`;
    case 'mobility_gold':
      return `${baseUrl}special_conditions_mobility_gold_${getI18nLanguageCode()}.pdf`;
    case 'family_mobility_gold':
      return `${baseUrl}special_conditions_family_mobility_gold_${getI18nLanguageCode()}.pdf`;
    default:
      return null;
  }
};

/**
 * Some insurance types are "sensitive" and therefore do not have commission or VK.
 * @param {string} insuranceType
 * @returns {boolean}
 */
export const isSensitiveInsurance = (insuranceType) =>
  ['funeral'].includes(insuranceType);

export const getOptions = (quote, onlyChosen) => {
  const optionsArray = [];

  // If not civli liability, add the base (ex: CIVIL_LIABILITY)
  if (quote.insurance?.key !== 'civil_liability' && quote?.quote?.base?.key) {
    optionsArray.push({
      key: quote?.quote?.base?.key,
      name: quote?.quote?.base?.name,
      mandatory: true,
    });
  }

  // If car, make sure to add the insurance (ex: OMNIUM)
  if (quote.insuranceType.key.toLowerCase() === 'car' && quote.insurance?.key)
    optionsArray.push({
      key: quote.insurance?.key,
      name: quote.insurance?.name,
      mandatory: true,
    });

  // Add remaining options (non-mandatory)
  const nonMandatoryOptions = quote.options
    // Filter if only chosen options should be included
    .filter(({ key }) =>
      quote.chosenOptions && onlyChosen
        ? quote.chosenOptions.includes(key)
        : true,
    )
    // Structure the object
    .map(({ name, key }) =>
      name
        ? { key, name: mapOptionName(name), mandatory: false }
        : { key, name: i18n.t(key), mandatory: false },
    );
  optionsArray.push(...nonMandatoryOptions);

  // Make sure that each option is unique in the array to avoid duplicates being rendered
  return uniqBy(
    optionsArray.filter(({ key }) => !!key),
    'key',
  );
};
